import Glide, { Swipe } from "@glidejs/glide/dist/glide.modular.esm";

export default function initPhotoHeaderSlides() {
  const photoSlidesContainer = document.querySelector(".photo-header-slides-container");
  if (!photoSlidesContainer) {
    return;
  }

  const glide = new Glide(photoSlidesContainer.querySelector(".glide"), {
    animationDuration: 600,
    peek: { before: 20, after: 20 },
    type: "carousel",
  });

  const editPhoto = photoSlidesContainer.querySelector(".edit-photo");
  const glideSlides = photoSlidesContainer.querySelectorAll(".glide__slide");
  glide.on("run", () => {
    const nextSlide = glideSlides[glide.index];
    if (nextSlide && editPhoto) {
      if (nextSlide.querySelector(".cta-slide")) {
        editPhoto.classList.add("d-none");
      } else {
        editPhoto.classList.remove("d-none");
      }
    }
  });

  glide.mount({ Swipe });
}
