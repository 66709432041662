export default function initAboutPage() {
  const publicSubscribers = document.querySelector(".public-subscribers");
  if (publicSubscribers) {
    const subscriberControls = document.querySelector(".subscriber-content-controls");
    if (subscriberControls) {
      const expandSubscribers = subscriberControls.querySelector(
        ".expand-subscriber-content",
      );
      const collapseSubscribers = subscriberControls.querySelector(
        ".collapse-subscriber-content",
      );

      // TODO: Considering using Bootstrap's collapse here
      expandSubscribers.addEventListener("click", () => {
        publicSubscribers.querySelectorAll(".below-fold").forEach((element) => {
          element.classList.remove("d-none");
        });
        expandSubscribers.classList.add("d-none");
        collapseSubscribers.classList.remove("d-none");
      });

      collapseSubscribers.addEventListener("click", () => {
        publicSubscribers.querySelectorAll(".below-fold").forEach((element) => {
          element.classList.add("d-none");
        });
        expandSubscribers.classList.remove("d-none");
        collapseSubscribers.classList.add("d-none");
      });
    }
  }
}
