import Modal from "bootstrap/js/dist/modal";

import initReCAPTCHAs from "../../core/reCAPTCHA";

export default function initMemorialHelpOut(props) {
  const needContainers = document.querySelectorAll(".help-out-need");
  if (needContainers) {
    needContainers.forEach((needContainer) => {
      const volunteerButton = needContainer.querySelector(".need-volunteer-button");
      if (volunteerButton) {
        volunteerButton.addEventListener("click", () => {
          const needUuid = needContainer.getAttribute("data-need");
          const needType = needContainer.getAttribute("data-need-type");
          const isAlreadyVolunteer = needContainer.getAttribute(
            "data-need-already-volunteered",
          );

          const needModalElement = document.querySelector("#need-volunteer-modal");
          const needModal = new Modal(needModalElement);
          const needInput = needModalElement.querySelector('input[name="need"]');

          needInput.value = needUuid;

          const form = needModalElement.querySelector("form");
          const personalShare = needModalElement.querySelector(".personal-share");
          const personalShareNext = personalShare.querySelector(".next");
          const socialShare = needModalElement.querySelector(".social-share");
          const passwordlessSignupContainer = needModalElement.querySelector(
            ".passwordless-signup-container",
          );

          // Reset in case we have past invocations.
          [personalShare, socialShare, passwordlessSignupContainer].forEach((e) => {
            e.classList.add("d-none");
          });

          let showModal = false;
          if (needType === props.NEED_TYPE_SHARE_PERSONAL) {
            showModal = true;
            personalShare.classList.remove("d-none");
            personalShare.querySelector(".btn-copy").addEventListener("click", () => {
              personalShareNext.classList.remove("d-none");
            });

            personalShareNext.addEventListener(
              "click",
              () => {
                if (props.isAuthenticated) {
                  if (isAlreadyVolunteer) {
                    needModal.hide();
                  } else {
                    form.requestSubmit();
                  }
                } else {
                  personalShare.classList.add("d-none");
                  passwordlessSignupContainer.classList.remove("d-none");
                }
              },
              { once: true },
            );
          } else if (needType === props.NEED_TYPE_SHARE_SOCIAL_MEDIA) {
            showModal = true;
            socialShare.classList.remove("d-none");
            window.addEventListener(
              "public-facebook-share-complete",
              () => {
                if (props.isAuthenticated) {
                  if (isAlreadyVolunteer) {
                    needModal.hide();
                  } else {
                    form.requestSubmit();
                  }
                } else {
                  socialShare.classList.add("d-none");
                  passwordlessSignupContainer.classList.remove("d-none");
                }
              },
              { once: true },
            );
          } else if (!props.isAuthenticated) {
            showModal = true;
            passwordlessSignupContainer.classList.remove("d-none");
          }

          if (showModal) {
            needModal.show();
          } else if (isAlreadyVolunteer) {
            needModal.hide();
          } else {
            form.submit();
          }
        });
      }
    });
  }

  window.initReCAPTCHAs = initReCAPTCHAs;
}
