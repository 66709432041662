import { initEditableUserPosts, initUserPostForms } from "../../components/UserPost";
import { scrollToElement } from "../../core/utils";
import { initMemorialPrivateFavorites } from "../private/private";

function initScrollToFavorite() {
  // Scroll to Favorite on public site
  const searchParams = new URLSearchParams(window.location.search);
  const uuidToScrollTo = searchParams.get("scroll-to");
  if (uuidToScrollTo) {
    const favoriteToScrollTo = document.querySelector(`#${CSS.escape(uuidToScrollTo)}`);
    if (favoriteToScrollTo) {
      scrollToElement(favoriteToScrollTo);
    }
  }
}

export default function initFavorites(props, isPrivate = false) {
  if (isPrivate) {
    initMemorialPrivateFavorites();
  } else {
    initScrollToFavorite();
  }

  initEditableUserPosts(props);
  initUserPostForms();

  if (window.location.hash === "#favorites") {
    scrollToElement(document.querySelector(".favorite"), -40);
  }
}
