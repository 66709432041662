import Modal from "bootstrap/js/dist/modal";

import { initProductPhotoBlur } from "./utils";

export default function initMemorialBelongings(props) {
  initProductPhotoBlur();

  document.querySelectorAll(".belonging").forEach((belonging) => {
    const hashid = belonging.dataset.belonging;

    const modalElement = document.querySelector(`.modal[data-belonging="${hashid}"]`);
    if (modalElement) {
      belonging.addEventListener("click", () => {
        Modal.getOrCreateInstance(modalElement).show();
      });
    }
  });

  if (props.show) {
    const modalElement = document.querySelector(
      `.modal[data-belonging="${props.show}"]`,
    );
    if (modalElement) {
      Modal.getOrCreateInstance(modalElement).show();
    }
  }
}
