import Modal from "bootstrap/js/dist/modal";

import initReCAPTCHAs from "../../core/reCAPTCHA";
import {
  animateCSS,
  scrollToElement,
  sleep,
  trackCustomUserAction,
} from "../../core/utils";
import { initEditableUserPosts, initUserPostForms } from "../../components/UserPost";
import initExpander from "../../components/Expander";
import { initAttendeeFormset } from "../rsvps";
import { initPasswordlessSignup } from "../../users/signup";

/**
 * Initialize event comments behavior -- dispatches to (editable) user post inits.
 *
 * @param {object} props - the page props
 */
function initEventComments(props) {
  initUserPostForms();
  initEditableUserPosts(props);
}

/**
 * Initialize the message-memorial-managers behaviors.
 */
function initMessageMemorialManagers() {
  const messageManagersLink = document.querySelector(".message-memorial-managers");
  const modalElement = document.querySelector("#modal-message-managers");

  if (!messageManagersLink || !modalElement) {
    return;
  }

  const modal = new Modal(modalElement);
  messageManagersLink.addEventListener("click", () => {
    modal.show();
  });

  const successModal = document.querySelector(".message-managers-success-modal");

  const setUpModalForm = () => {
    const form = modalElement.querySelector("form");
    const textarea = form.querySelector("#message-managers--textarea");
    const submitButton = form.querySelector(".message-managers--submit");
    const cancelButton = form.querySelector(".message-managers--cancel");

    ["input", "change"].forEach((evt) => {
      textarea.addEventListener(evt, () => {
        if (textarea.value) {
          submitButton.disabled = false;
        } else {
          submitButton.disabled = true;
        }
      });
    });

    cancelButton.addEventListener("click", () => {
      modal.hide();
    });
  };

  const toggleErrorMessage = (error) => {
    const form = modalElement.querySelector("form");
    const errorMessage = form.querySelector(".error-message");

    errorMessage.classList.toggle("d-none", !error);
  };

  setUpModalForm();

  let hasServerError = false;

  document.body.addEventListener("htmx:responseError", () => {
    hasServerError = true;
    toggleErrorMessage(true);
  });

  document.body.addEventListener("htmx:afterRequest", async () => {
    if (!hasServerError) {
      modal.hide();
      toggleErrorMessage(false);
      successModal.classList.remove("fadeIn");
      await animateCSS(successModal, "fadeIn");
      await sleep(5000);
      await animateCSS(successModal, "fadeOut");
      successModal.classList.add("d-none");
    }
  });

  document.body.addEventListener("htmx:afterSettle", (e) => {
    if (e.detail.elt.id === "message-managers--form") {
      setUpModalForm();
    }
  });
}

/**
 * Initialize the public memorial events page -- RSVP interactions, signup on RSVP,
 * message managers, event comments, show/hide stuff.
 *
 * @param {object} props - the page props.
 */
export default function initMemorialEvents(props) {
  initPasswordlessSignup();
  initEventComments(props);
  initMessageMemorialManagers();

  const disclaimerNameSection = document.querySelector(".disclaimer span");
  const rsvpSelects = document.querySelectorAll(".attending select");

  rsvpSelects.forEach((select) => {
    const handleAttendanceSelection = () => {
      const form = select.closest("form");
      const details = form.querySelector(".rsvp-details");

      const selectedOptionValue = select.selectedOptions.length
        ? select.selectedOptions[0].value
        : null;
      const isAttending = selectedOptionValue
        ? ["attending", "attending_virtually"].includes(selectedOptionValue)
        : false;

      if (details) {
        details.classList.toggle("rsvp-details-collapsed", !selectedOptionValue);
      }

      const signup = form.querySelector(".rsvp-passwordless-signup");
      if (signup) {
        signup.classList.toggle("d-none", !selectedOptionValue);
      }

      const numberOfAttendeesInput = details.querySelector('input[type="number"]');
      if (numberOfAttendeesInput) {
        numberOfAttendeesInput.setAttribute("min", isAttending ? "1" : "0");
      }

      if (disclaimerNameSection) {
        disclaimerNameSection.classList.toggle("d-none", !isAttending);
      }

      form
        .querySelector(".attendees")
        .classList.toggle("rsvp-attendees-collapsed", !isAttending);

      return isAttending;
    };

    select.addEventListener("change", () => {
      const isAttending = handleAttendanceSelection();
      trackCustomUserAction(
        window.location.href,
        props.rsvpEventCategory,
        isAttending
          ? props.rsvpEventActionAttending
          : props.rsvpEventActionNotAttending,
      );
    });

    handleAttendanceSelection();
  });

  document.querySelectorAll(".rsvp-toggle").forEach((rsvpToggle) => {
    rsvpToggle.addEventListener("click", () => {
      rsvpToggle.classList.toggle("rsvp-expanded");
      rsvpToggle.querySelector(".collapsed-img").classList.toggle("d-none");
      rsvpToggle.querySelector(".expanded-img").classList.toggle("d-none");

      rsvpToggle
        .closest(".memorial-event")
        .querySelector(".event-metadata")
        .querySelectorAll(":scope > .rsvp")
        .forEach((rsvp) => rsvp.classList.toggle("rsvp-collapsed"));
    });
  });

  document.querySelectorAll(".btn-cancel-rsvp").forEach((cancelBtn) =>
    cancelBtn.addEventListener("click", () => {
      const form = cancelBtn.closest("form");
      const rsvpToggle = cancelBtn
        .closest(".memorial-event")
        .querySelector(".rsvp-toggle");

      if (rsvpToggle) {
        rsvpToggle.click();
      } else {
        const radios = form.querySelectorAll('input[type="radio"]');
        radios.forEach((radio) => {
          radio.checked = false;
        });

        form.querySelector(".attending").classList.remove("el-input-group");
        form.querySelector(".rsvp-details").classList.add("rsvp-details-collapsed");
        form.querySelector(".attendees").classList.add("rsvp-attendees-collapsed");
      }
    }),
  );

  if (window.location.hash !== "") {
    let hashedElement;

    try {
      hashedElement = document.querySelector(
        `#${CSS.escape(window.location.hash.slice(1))}`,
      );
    } catch {
      hashedElement = null;
    }

    if (
      hashedElement &&
      [".rsvp-toggle", ".collapsed-img", ".expanded-img", ".event-metadata"].every(
        (qs) => hashedElement.querySelector(qs),
      )
    ) {
      hashedElement.querySelector(".rsvp-toggle").classList.toggle("rsvp-expanded");
      hashedElement.querySelector(".collapsed-img").classList.toggle("d-none");
      hashedElement.querySelector(".expanded-img").classList.toggle("d-none");
      hashedElement
        .querySelector(".event-metadata")
        .querySelectorAll(".rsvp")
        .forEach((rsvpChild) => {
          rsvpChild.classList.remove("rsvp-collapsed");
        });
    }
  }

  // scroll to event if user has rsvp'd.
  const searchParams = new URLSearchParams(window.location.search);
  const uuidToScrollTo = searchParams.get("scroll-to");
  if (uuidToScrollTo) {
    const memorialEventOrCommentToScrollTo = document.querySelector(
      `#${CSS.escape(uuidToScrollTo)}`,
    );
    if (memorialEventOrCommentToScrollTo) {
      scrollToElement(memorialEventOrCommentToScrollTo);
    }
  }

  const eventSpeechForms = document.querySelectorAll(".event-speech-form");
  eventSpeechForms.forEach((speechForm) => {
    const speechTypeSelect = speechForm.querySelector(".speech-type-select select");
    const speechTypeStrContainer = speechForm.querySelector(".speech-type-str");

    if (speechTypeSelect && speechTypeStrContainer) {
      speechTypeSelect.addEventListener("change", () => {
        const selected = speechTypeSelect.selectedOptions[0];
        speechTypeStrContainer.classList.toggle(
          "d-none",
          !(selected && selected.value === props.SPEECH_TYPE_OTHER),
        );
      });
    }
  });

  // Initialize RSVP additional attendees formset(s)
  if (props.attendeeFormsetProps) {
    Object.keys(props.attendeeFormsetProps).forEach((eventUUID) => {
      const formsetProps = props.attendeeFormsetProps[eventUUID];
      initAttendeeFormset(eventUUID, formsetProps);
    });
  }

  if (props.errorRSVPEventUuid) {
    const eventContainer = document.getElementById(props.errorRSVPEventUuid);
    if (eventContainer) {
      const details = eventContainer.querySelector(".rsvp-details");
      if (details) {
        details.classList.remove("rsvp-details-collapsed");
      }
    }
  }

  document.querySelectorAll(".event-speaker-speech").forEach((userPost) => {
    initExpander(userPost);
  });

  window.initReCAPTCHAs = initReCAPTCHAs;
}
