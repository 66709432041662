import throttle from "lodash.throttle";

import initDonorChoiceCharities from "../../components/DonorChoiceCharities";
import initSuccessModal from "../../components/SuccessModal";
import {
  fadeOut,
  getElementDocumentOffset,
  isVisible,
  scrollToElement,
} from "../../core/utils";
import requests, { ResponseError } from "../../core/requests";

function updateSharingContainerVisibility(donateCta, scrollThreshold) {
  if (window.scrollY >= scrollThreshold) {
    donateCta.classList.remove("d-none");
  } else {
    donateCta.classList.add("d-none");
  }
}

export default async function initMemorialDonationPage(props) {
  initSuccessModal();

  const successModalWithLink = document.querySelector(
    ".success-modal-container-with-link",
  );
  if (successModalWithLink) {
    fadeOut(successModalWithLink, 12000);
  } else {
    [...document.querySelectorAll(".success-modal-container")]
      .filter((element) => isVisible(element))
      .forEach(async (element) => {
        fadeOut(element, 5000);
      });
  }

  initDonorChoiceCharities(props);

  if (!props.donorChoiceDonationsMode) {
    const donateCta = document.querySelector(".donate-cta-tablet-mobile");
    const thresholdElement = [...document.querySelectorAll(".donate-cta")].find(
      isVisible,
    );

    if (donateCta && thresholdElement) {
      const scrollThreshold =
        getElementDocumentOffset(thresholdElement).top +
        thresholdElement.getBoundingClientRect().height;

      const wrappedUpdateSharingContainerVisibility = () => {
        updateSharingContainerVisibility(donateCta, scrollThreshold);
      };

      document.addEventListener(
        "scroll",
        throttle(wrappedUpdateSharingContainerVisibility, 16),
      );
    }
  }

  const searchParams = new URLSearchParams(window.location.search);
  const uuidToScrollTo = searchParams.get("scroll-to");
  if (uuidToScrollTo) {
    const donationToScrollTo = document.querySelector(`#${CSS.escape(uuidToScrollTo)}`);
    if (donationToScrollTo) {
      scrollToElement(donationToScrollTo);
    }
  }

  document.querySelectorAll(".btn-load-more").forEach((loadMoreBtn) => {
    const errorList = loadMoreBtn.nextElementSibling;
    let pageNumber = 2;

    loadMoreBtn.addEventListener("click", async () => {
      errorList.classList.add("d-none");
      while (errorList.hasChildNodes()) {
        errorList.removeChild(errorList.lastChild);
      }

      // TODO: Refactor this into HTMX
      let responseData;
      try {
        const response = await requests.get(props.getContributionsUrl, {
          cutoff: loadMoreBtn.dataset.timestamp,
          fundraiser_id: loadMoreBtn.dataset.fundraiserId,
          page: pageNumber,
          past: loadMoreBtn.dataset.past === "true",
        });

        if (!response.ok) {
          throw new ResponseError(response);
        }

        responseData = await response.json();
      } catch (error) {
        if (error.response && error.response.status === 429) {
          errorList.insertAdjacentHTML(
            "beforeend",
            "<li>You're loading contributions too often. Please try again later.</li>",
          );
        } else {
          window.Rollbar.error("Error loading contributions", error);

          errorList.insertAdjacentHTML(
            "beforeend",
            "<li>We've experienced an error loading contributions. Please reload the page and try again.</li>",
          );
        }

        errorList.classList.remove("d-none");
      }

      if (responseData) {
        if (responseData.rendered) {
          loadMoreBtn.insertAdjacentHTML("beforebegin", responseData.rendered);
        }

        if (responseData.hasNext) {
          pageNumber += 1;
        } else {
          loadMoreBtn.remove();
        }
      }
    });
  });
}
