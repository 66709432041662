/**
 * Initialize our character limit component.
 *
 * This component shows the remaining characters left for a given input and limit.
 *
 * @param {HTMLInputElement} input - the input Element
 * @param {number} limit - the number of characters to limit to
 * @param {boolean} [insertAfter] - if true, insert the limit span after the input.
 * @param {(null|Element)} [existingLimitSpan] - an existing limit span (optional)
 * @param {(null|Function)} [generateLimitString] - a function generating limit text (optional)
 */
export default function initCharacterLimitTextInput(
  input,
  limit,
  insertAfter = false,
  existingLimitSpan = null,
  generateLimitString = null,
) {
  let limitSpan;
  if (existingLimitSpan) {
    limitSpan = existingLimitSpan;
  } else {
    limitSpan = document.createElement("span");
    limitSpan.classList.add("character-limit");

    if (insertAfter) {
      input.after(limitSpan);
    } else {
      input.before(limitSpan);
    }
  }

  const orangeLimit = Math.floor(limit / 4);
  const redLimit = Math.floor(limit / 10);

  const refresh = (changeEvent) => {
    const changedInput = changeEvent.target;
    const remaining = limit - changedInput.value.length;

    if (generateLimitString) {
      limitSpan.innerText = generateLimitString(remaining);
    } else {
      limitSpan.innerText = `${remaining}`;
    }
    if (remaining <= redLimit) {
      limitSpan.classList.add("red");
      limitSpan.classList.remove("orange");
    } else if (remaining <= orangeLimit) {
      limitSpan.classList.add("orange");
      limitSpan.classList.remove("red");
    } else {
      limitSpan.classList.remove("red");
      limitSpan.classList.remove("orange");
    }
  };

  input.addEventListener("input", refresh);
  refresh({ target: input });
}
