import Modal from "bootstrap/js/dist/modal";

import { initMemorialDeceasedForm } from "../private/private";
import initReCAPTCHAs from "../../core/reCAPTCHA";

export default function initMemorialCreate(props) {
  const { hasSignupForm } = props;

  const generateObituaryCreateModal = document.getElementById(
    "generate-obituary-create-modal",
  );
  if (generateObituaryCreateModal) {
    Modal.getOrCreateInstance(generateObituaryCreateModal).show();
  }

  initMemorialDeceasedForm(props);

  if (hasSignupForm) {
    window.initReCAPTCHAs = initReCAPTCHAs;
  }

  if (props.isPreDeathChecklist) {
    const preDeathIsSelfRadios = document.querySelectorAll(
      'input[name="pre-death-is-self"]',
    );
    const relationshipSelector = document.querySelector(
      `#${props.relationshipSelectId}`,
    );
    const executorForm = document.querySelector(".estate-executor");
    const executorRadios = document.querySelectorAll(
      `input[name="${props.isExecutorRadioName}"]`,
    );
    const deceasedFormContainer = document.querySelector(
      ".memorial-deceased-form-container",
    );
    const passwordlessSignupContainer = document.querySelector(
      ".passwordless-signup-container",
    );

    const toggleSubmitEnabled = (enabled) => {
      const submitButton = document.querySelector(".btn-form-submit button");
      if (submitButton) {
        submitButton.classList.toggle("disabled", !enabled);
        if (enabled) {
          submitButton.removeAttribute("disabled");
        } else {
          submitButton.setAttribute("disabled", "disabled");
        }
      }
    };

    toggleSubmitEnabled(false);

    const shouldShowExecutor = () => {
      if (relationshipSelector && relationshipSelector.selectedOptions.length > 0) {
        const selectedOption = relationshipSelector.selectedOptions[0];
        return !props.nonExecutorRelationshipTypes.includes(selectedOption.value);
      }

      return false;
    };

    const updateForSelf = (isForSelf) => {
      const elementsForSelf = document.querySelectorAll('[data-for-self="1"]');
      const elementsForNotSelf = document.querySelectorAll('[data-for-self="0"]');

      elementsForSelf.forEach((element) => {
        element.classList.toggle("d-none", !isForSelf);
      });

      elementsForNotSelf.forEach((element) => {
        element.classList.toggle("d-none", isForSelf);
      });

      if (isForSelf) {
        executorRadios.forEach((radio) => {
          radio.checked = false;
        });
      }

      const executorShouldShow = shouldShowExecutor();

      if (executorForm) {
        executorForm.classList.toggle("d-none", !(!isForSelf && executorShouldShow));
      }

      relationshipSelector.value = null;
      const relationshipSelected = relationshipSelector.selectedOptions.length > 0;
      const deceasedFormShouldShow = isForSelf || relationshipSelected;

      deceasedFormContainer.classList.toggle("d-none", !deceasedFormShouldShow);
      if (passwordlessSignupContainer) {
        passwordlessSignupContainer.classList.toggle("d-none", !deceasedFormShouldShow);
      }
      if (deceasedFormShouldShow) {
        document.querySelector(".for-self-divider").classList.remove("d-none");
      }

      toggleSubmitEnabled(deceasedFormShouldShow);

      const firstNameField = document.getElementById(props.firstNameField);
      const lastNameField = document.getElementById(props.lastNameField);

      if (firstNameField && lastNameField && props.loggedInUserName) {
        const { firstName, lastName } = props.loggedInUserName;

        if (isForSelf) {
          if (!(firstNameField.value || lastNameField.value)) {
            firstNameField.value = firstName;
            lastNameField.value = lastName;
          }
        } else if (
          firstNameField.value === firstName &&
          lastNameField.value === lastName
        ) {
          firstNameField.value = "";
          lastNameField.value = "";
        }
      }
    };

    relationshipSelector.addEventListener("change", () => {
      const isForSelf = document.querySelector(
        'input[name="pre-death-is-self"][value="1"]:checked',
      );
      const executorShouldShow = shouldShowExecutor();

      if (executorForm) {
        executorForm.classList.toggle("d-none", !(!isForSelf && executorShouldShow));
      }

      deceasedFormContainer.classList.remove("d-none");
      if (passwordlessSignupContainer) {
        passwordlessSignupContainer.classList.remove("d-none");
      }
      document.querySelector(".for-self-divider").classList.remove("d-none");

      toggleSubmitEnabled(true);
    });

    preDeathIsSelfRadios.forEach((radio) => {
      radio.addEventListener("change", () => {
        document.querySelector(".for-self-divider").classList.add("d-none");
        const isForSelf = radio.checked && radio.value === "1";
        updateForSelf(isForSelf);
      });
    });

    executorRadios.forEach((radio) => {
      radio.addEventListener("change", () => {
        if (radio.checked) {
          if (passwordlessSignupContainer) {
            passwordlessSignupContainer.classList.remove("d-none");
          }
        }
      });
    });

    if (props.preDeathIsSelf !== null) {
      const radio = document.querySelector(
        `input[name="pre-death-is-self"][value="${props.preDeathIsSelf ? "1" : "0"}"]`,
      );
      if (radio) {
        radio.checked = true;
      }
      updateForSelf(props.preDeathIsSelf);
    }
  }

  const recentlyCreatedContainer = document.querySelector(
    ".recently-created-container",
  );
  const dismissRecentlyCreated = document.querySelector(".dismiss-recently-created");

  if (recentlyCreatedContainer && dismissRecentlyCreated) {
    dismissRecentlyCreated.addEventListener("click", () => {
      recentlyCreatedContainer.classList.add("dismissed");
    });
  }
}
