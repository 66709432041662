import Collapse from "bootstrap/js/dist/collapse";
import Modal from "bootstrap/js/dist/modal";

import initGoogleAutocomplete from "../../components/GoogleAutocomplete";
import initParticipantAutocomplete from "../../components/Participant";
import initReCAPTCHAs from "../../core/reCAPTCHA";
import {
  initEditableUserPost,
  initEditableUserPosts,
  initUserPostForm,
  initUserPostForms,
} from "../../components/UserPost";
import {
  animateCSS,
  initDirtyTracker,
  isVisible,
  readValidFileFromInput,
  scrollToElement,
  trackCustomUserAction,
} from "../../core/utils";
import MemoryMultiPhotoUploader from "../../components/MemoryMultiPhotoUploader";
import {
  SocialPhotoImporter,
  SocialSource,
} from "../../components/SocialPhotoImporter";
import { initEditDropdown } from "../../components/EditMenuDropdowns";
import { initPhotoArchiveRequest } from "../private/private";
import requests, { ResponseError } from "../../core/requests";
import { initPasswordlessSignup } from "../../users/signup";

/**
 * Initialize memories page behavior.
 *
 * @param {object} props - the page props
 */
export default function initMemories(props) {
  const memoryDetailsContainer = document.querySelector(".add-memory-details");
  const memoryForm = document.querySelector(".memory-form");
  const multiPhotoUploaderContainer = document.querySelector(".multi-photo-uploader");
  const btn = document.querySelector(".btn-submit-memory");
  const cancelBtn = document.querySelector(".btn-cancel-memory-edit");
  const captionInput = document.querySelector(".caption-input");
  const locationInput = document.querySelector(".location-input");
  const memorySelectors = document.querySelectorAll(".memory-selector");
  const mediumEditorTextArea = document.querySelector(".medium-editor-textarea");
  const memoryType = document.getElementById("id_memory-memory_type");
  const participantsInput = document.querySelector(".participants-input");
  const photoFileInput = document.getElementById("id_memory-photo");
  const photoInput = document.querySelector(".photo-input");
  const textInput = document.querySelector(".text-input");
  const textInputLabels = document.querySelectorAll(".text-input-label");
  const yearInput = document.querySelector(".year-input");
  const videoInput = document.querySelector(".video-input");
  const videoInputURL = document.getElementById("id_memory-video_url");
  const ingestionModalElement = document.querySelector(".photo-ingestion-modal");
  const condolenceTemplatesContainer = document.querySelector(
    ".condolence-templates-container",
  );

  let ingestionModal;
  if (ingestionModalElement) {
    ingestionModal = Modal.getOrCreateInstance(ingestionModalElement);
  }

  if (photoFileInput) {
    photoFileInput.addEventListener("change", () => {
      readValidFileFromInput(photoFileInput);
    });
  }

  let multiPhotoUploader;
  if (!props.memoryPostingNotAllowed) {
    // Multiple photo upload
    if (multiPhotoUploaderContainer) {
      multiPhotoUploader = new MemoryMultiPhotoUploader(
        multiPhotoUploaderContainer.querySelector("form"),
        props.photoMemoryFormsetIdPrefix,
        props.emptyPhotoMemoryForm,
        Array.from(document.querySelectorAll(".photo-memory-form")),
        props.userMention,
        props.availableDeceasedPhotoSlots,
      );
    } else {
      initParticipantAutocomplete(props);
    }
  }

  /**
   * Show the photo memory form
   */
  const showPhotoMemoryForm = () => {
    if (props.editing) {
      btn.innerText = "Save changes";
    } else if (props.postDonation) {
      btn.innerText = "Share";
      cancelBtn.innerText = "Skip";
    } else {
      btn.innerText = "Post photo";
    }

    captionInput.classList.remove("d-none");
    locationInput.classList.remove("d-none");
    photoInput.classList.remove("d-none");
    participantsInput.classList.remove("d-none");
    textInput.classList.add("d-none");
    yearInput.classList.remove("d-none");
    videoInput.classList.add("d-none");
    videoInputURL.disabled = true;
    textInputLabels.forEach((label) => {
      label.innerText = "Caption";
    });
    memoryType.value = props.memoryTypePhoto;
    if (condolenceTemplatesContainer) {
      condolenceTemplatesContainer.classList.add("d-none");
    }
    textInput.querySelector("textarea").setAttribute("placeholder", "");
  };

  /**
   * Show the multi-photo uploader.
   */
  const showMultiPhotoUploader = () => {
    if (props.emptyPhotoMemoryForm && multiPhotoUploaderContainer) {
      memoryForm.classList.add("d-none");
      multiPhotoUploaderContainer.classList.remove("d-none");
    }
  };

  /**
   * Show either the single or multi photo uploader.
   */
  const showPhotoUploader = () => {
    if (ingestionModalElement) {
      // Hide FB option of browser is blocking FB JS.
      if (typeof window.FB === "undefined") {
        ingestionModalElement
          .querySelector(".ingestion-option.facebook")
          .classList.add("d-none");
      }
      ingestionModal.show();
      showMultiPhotoUploader();
    } else if (multiPhotoUploader) {
      multiPhotoUploader.show();
      showMultiPhotoUploader();
    } else {
      document.getElementById("id_memory-photo").click();
    }
  };

  /**
   * Show the story-entry mode of the standard memory form.
   */
  const showStoryMemoryForm = () => {
    if (props.editing) {
      btn.innerText = "Save changes";
    } else if (props.postDonation) {
      btn.innerText = "Share";
      cancelBtn.innerText = "Skip";
    } else {
      btn.innerText = "Post story";
    }

    captionInput.classList.add("d-none");
    locationInput.classList.remove("d-none");
    photoInput.classList.add("d-none");
    participantsInput.classList.add("d-none");
    textInput.classList.remove("d-none");
    yearInput.classList.remove("d-none");
    videoInput.classList.add("d-none");
    videoInputURL.disabled = true;
    textInputLabels.forEach((label) => {
      label.innerText = `Story about ${props.deceasedFirstName}`;
    });
    memoryType.value = props.memoryTypeStory;

    if (condolenceTemplatesContainer) {
      condolenceTemplatesContainer.classList.add("d-none");
    }

    textInput.querySelector("textarea").setAttribute("placeholder", "");
  };

  /**
   * Show the condolence-mode of the standard memory form.
   */
  const showCondolenceMemoryForm = () => {
    if (props.editing) {
      btn.innerText = "Save changes";
    } else if (props.postDonation) {
      btn.innerText = "Share";
      cancelBtn.innerText = "Skip";
    } else {
      btn.innerText = "Post condolence";
    }

    captionInput.classList.add("d-none");
    locationInput.classList.add("d-none");
    photoInput.classList.add("d-none");
    participantsInput.classList.add("d-none");
    textInput.classList.remove("d-none");
    yearInput.classList.add("d-none");
    videoInput.classList.add("d-none");
    videoInputURL.disabled = true;
    textInputLabels.forEach((label) => {
      label.innerText = "Your condolence";
    });
    memoryType.value = props.memoryTypeCondolence;

    if (condolenceTemplatesContainer) {
      condolenceTemplatesContainer.classList.remove("d-none");
    }

    textInput.querySelector("textarea").setAttribute("placeholder", "");
  };

  /**
   * Show the video-entry mode of the standard memory form.
   */
  const showVideoMemoryForm = () => {
    if (props.editing) {
      btn.innerText = "Save changes";
    } else if (props.postDonation) {
      btn.innerText = "Share";
      cancelBtn.innerText = "Skip";
    } else {
      btn.innerText = "Post video";
    }

    captionInput.classList.add("d-none");
    locationInput.classList.add("d-none");
    photoInput.classList.add("d-none");
    participantsInput.classList.add("d-none");
    textInput.classList.remove("d-none");
    yearInput.classList.add("d-none");
    videoInput.classList.remove("d-none");
    videoInputURL.disabled = false;
    textInputLabels.forEach((label) => {
      label.innerText = "Video description";
    });
    memoryType.value = props.memoryTypeVideo;

    if (condolenceTemplatesContainer) {
      condolenceTemplatesContainer.classList.add("d-none");
    }

    textInput
      .querySelector("textarea")
      .setAttribute(
        "placeholder",
        `Ex. The video from ${props.deceasedFirstName}'s memorial service`,
      );
  };

  /**
   * Based on which memory type selector is selected, display and hide the proper
   * memory forms.
   *
   * @param {Element} selected - the selected memory type selector
   */
  const toggleMemoryForms = (selected) => {
    memorySelectors.forEach((currentSelector) => {
      if (selected !== currentSelector) {
        currentSelector.classList.remove("selected");
        currentSelector.classList.add("unselected");
        currentSelector.querySelector("img.unselected").classList.remove("d-none");
        currentSelector.querySelector("img.selected").classList.add("d-none");
      } else {
        currentSelector.classList.add("selected");
        currentSelector.classList.remove("unselected");
        currentSelector.querySelector("img.unselected").classList.add("d-none");
        currentSelector.querySelector("img.selected").classList.remove("d-none");
      }
    });
  };

  /**
   * Display the proper memory form on load of the page.
   */
  const openMemoryTypeFormOnLoad = () => {
    if (!memoryDetailsContainer) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const memoryTypeParam = urlParams.get("share-memory");
    let selectedMemoryType;
    if (props.memoryType !== undefined) {
      selectedMemoryType = props.memoryType;
    } else if (memoryTypeParam !== undefined) {
      selectedMemoryType = memoryTypeParam;
    } else {
      return;
    }

    selectedMemoryType = parseInt(selectedMemoryType, 10);

    memoryDetailsContainer.classList.remove("d-none");
    let selected = document.querySelector(".give-condolences");
    if (selectedMemoryType === props.memoryTypePhoto) {
      showPhotoMemoryForm();
      showPhotoUploader();
      selected = document.querySelector(".share-photo");
    } else if (selectedMemoryType === props.memoryTypeStory) {
      showStoryMemoryForm();
      selected = document.querySelector(".tell-story");
    } else if (selectedMemoryType === props.memoryTypeVideo) {
      showVideoMemoryForm();
      selected = document.querySelector(".share-video");
    } else {
      showCondolenceMemoryForm();
    }

    toggleMemoryForms(selected);
  };

  const passwordlessSignupContainer = document.querySelector(
    ".passwordless-signup-container",
  );
  if (passwordlessSignupContainer) {
    const passwordlessSignupCollapse = Collapse.getOrCreateInstance(
      passwordlessSignupContainer,
      { toggle: false },
    );

    passwordlessSignupContainer
      .querySelectorAll("input")
      .forEach((passwordlessInput) => {
        passwordlessInput.addEventListener("invalid", () => {
          passwordlessSignupCollapse.show();
        });
      });

    const formInputElementsToListenTo = [
      videoInputURL,
      photoFileInput,
      mediumEditorTextArea,
    ];
    formInputElementsToListenTo.forEach((el) => {
      if (el) {
        el.addEventListener("input", () => {
          if (el.value) {
            passwordlessSignupCollapse.show();
          }
        });
      }
    });
  }

  ["click", "touchstart"].forEach((eventType) =>
    memorySelectors.forEach((selector) =>
      selector.addEventListener(eventType, (event) => {
        // NOTE(eric): we need to stop an event from propagating here on iOS
        // because we use a hover state
        event.stopPropagation();

        memoryDetailsContainer.classList.remove("d-none");
        memoryForm.classList.remove("d-none");

        // Hide by default; will be un-hidden by photo selection
        if (props.emptyPhotoMemoryForm && multiPhotoUploaderContainer) {
          multiPhotoUploaderContainer.classList.add("d-none");
        }

        if (selector.classList.contains("share-photo")) {
          if (props.memorialIsExample) {
            Modal.getOrCreateInstance("#modal-example").show();
          } else {
            showPhotoMemoryForm();
            showPhotoUploader();

            if (props.editing) {
              trackCustomUserAction(
                window.location.href,
                "memory",
                "edit-change-type-to-photo",
              );
            } else {
              trackCustomUserAction(window.location.href, "memory", "select-photo");
            }
          }
        } else if (selector.classList.contains("tell-story")) {
          showStoryMemoryForm();
          if (props.editing) {
            trackCustomUserAction(
              window.location.href,
              "memory",
              "edit-change-type-to-story",
            );
          } else {
            trackCustomUserAction(window.location.href, "memory", "select-story");
          }
        } else if (selector.classList.contains("share-video")) {
          showVideoMemoryForm();
          if (props.editing) {
            trackCustomUserAction(
              window.location.href,
              "memory",
              "edit-change-type-to-video",
            );
          } else {
            trackCustomUserAction(window.location.href, "memory", "select-video");
          }
        } else {
          showCondolenceMemoryForm();
          if (props.editing) {
            trackCustomUserAction(
              window.location.href,
              "memory",
              "edit-change-type-to-condolence",
            );
          } else {
            trackCustomUserAction(window.location.href, "memory", "select-condolence");
          }
        }

        toggleMemoryForms(selector);
      }),
    ),
  );

  if (cancelBtn) {
    cancelBtn.addEventListener("click", (event) => {
      if (!props.editing && !props.postDonation) {
        memoryDetailsContainer.classList.add("d-none");
        memoryForm.reset();

        memorySelectors.forEach((selector) => {
          selector.classList.remove("selected");
          selector.classList.add("unselected");
          selector.querySelector("img.unselected").classList.remove("d-none");
          selector.querySelector("img.selected").classList.add("d-none");
        });

        event.preventDefault();
      }
    });
  }

  openMemoryTypeFormOnLoad();

  initUserPostForms();
  initEditableUserPosts(props);

  // HEARTS

  if (window.location.search.indexOf("memory-heart-added") !== -1) {
    let heartedMemory;
    try {
      heartedMemory = document.querySelector(
        `#${CSS.escape(window.location.hash.slice(1))}`,
      );
    } catch {
      heartedMemory = null;
    }

    if (heartedMemory) {
      const fullHeart = heartedMemory.querySelector(".memory-heart-toggle img");
      if (fullHeart) {
        animateCSS(fullHeart, "pulse");
      }
    }
  }

  /**
   * Given user names from a heart-POST response, update the heart display content.
   *
   * @param {Array} userNames - an array of user names
   * @param {Element} heartUsersWrapper - the container for the users list
   */
  const repopulateHeartUserNames = (userNames, heartUsersWrapper) => {
    heartUsersWrapper.querySelectorAll(".user-heart").forEach((heart) => {
      heart.remove();
    });

    const heartUserTemplate = heartUsersWrapper.querySelector(".empty-template");
    const leftColumn = heartUsersWrapper.querySelector(
      ".memory-heart-user-list-column:first-child",
    );
    const rightColumn = heartUsersWrapper.querySelector(
      ".memory-heart-user-list-column:last-child",
    );
    const leftArray = [];
    const rightArray = [];
    const numNamesForLeftArray = Math.ceil(userNames.length / 2);

    // iterate over list of names and use empty template to re-populate
    // the two columns of names
    userNames.forEach((value, i) => {
      const thisTemplate = heartUserTemplate.cloneNode(true);
      thisTemplate.querySelector("span").innerText = value;
      thisTemplate.classList.remove("d-none");
      thisTemplate.classList.add("user-heart");
      thisTemplate.classList.remove("empty-template");
      if (i + 1 <= numNamesForLeftArray) {
        leftArray.push(thisTemplate);
      } else {
        rightArray.push(thisTemplate);
      }
    });

    leftArray.forEach((n) => {
      leftColumn.appendChild(n);
    });
    rightArray.forEach((n) => {
      rightColumn.appendChild(n);
    });
  };

  /**
   * Initialize the interactive features of a memory in the list of memories.
   *
   * @param {Element} memory - the memory-containing element.
   */
  const initMemoryInteractivity = (memory) => {
    const heartToggleButton = memory.querySelector(".memory-heart-toggle");
    const memoryReactionsWrapper = memory.querySelector(".memory-reactions");
    const heartCount = memory.querySelector(".heart-count");
    const heartUserCollapser = memory.querySelector(".collapser");
    const heartUsersWrapper = memory.querySelector(".memory-heart-users");
    const heartUsersCollapse = heartUserCollapser
      ? Collapse.getOrCreateInstance(heartUsersWrapper, { toggle: false })
      : null;
    const pinToggle = memory.querySelector(".memory-pin-toggle");
    const commentBtn = memory.querySelector(".comment-button");
    const commentField = memory.querySelector("textarea.answer-text");

    if (heartToggleButton) {
      const heartToggleUrl = heartToggleButton.dataset.toggleUrl;
      const fullHeart = heartToggleButton.querySelector("img:first-child");

      heartToggleButton.addEventListener("click", async () => {
        let responseData;

        try {
          const response = await requests.post(heartToggleUrl);

          if (!response.ok) {
            throw new ResponseError(response);
          }

          responseData = await response.json();
        } catch (error) {
          window.Rollbar.warn("Error hearting a memory", error);
        }

        if (responseData) {
          heartToggleButton.querySelectorAll("img").forEach((img) => {
            img.classList.toggle("d-none");
          });
          // increment, add, or hide heart counter and class for extra margin based on heart_count
          if (responseData.heart_count > 0) {
            heartCount.innerText = `${responseData.heart_count}`;
            heartCount.classList.remove("d-none");
            heartCount.parentElement.classList.add("heart-or-comment-counter-visible");
          } else {
            heartCount.innerText = "";
            heartCount.classList.add("d-none");
            if (!memoryReactionsWrapper.querySelector(".comment-count")) {
              heartCount.parentElement.classList.remove(
                "heart-or-comment-counter-visible",
              );
            }
          }
          // toggle appearance of heart and repopulate list of names of users who have hearted
          // this memory based on whether user is adding or removing a heart
          if (responseData.is_active) {
            animateCSS(fullHeart, "pulse");
            repopulateHeartUserNames(responseData.user_names, heartUsersWrapper);
            trackCustomUserAction(
              window.location.href,
              "memory",
              "click-memory-heart-on",
            );
          } else {
            repopulateHeartUserNames(responseData.user_names, heartUsersWrapper);

            trackCustomUserAction(
              window.location.href,
              "memory",
              "click-memory-heart-off",
            );
            if (
              !memoryReactionsWrapper.querySelectorAll(
                ".memory-heart-user-list-column .user-heart",
              ).length
            ) {
              if (heartUsersCollapse) {
                heartUsersCollapse.hide();
              }
            }
          }
        }
      });
    }

    if (heartUsersWrapper) {
      heartUsersWrapper.addEventListener("shown.bs.collapse", () => {
        trackCustomUserAction(
          window.location.href,
          "memory",
          "click-memory-open-heart-list",
        );
      });
      heartUsersWrapper.addEventListener("hidden.bs.collapse", () => {
        trackCustomUserAction(
          window.location.href,
          "memory",
          "click-memory-close-heart-list",
        );
      });
    }

    const toggleHeartUsers = () => {
      if (heartUsersCollapse) {
        heartUsersCollapse.toggle();
      }
    };

    if (heartCount) {
      heartCount.addEventListener("click", toggleHeartUsers);
    }

    if (heartUserCollapser) {
      heartUserCollapser.addEventListener("click", toggleHeartUsers);
    }

    if (pinToggle) {
      const pinToggleUrl = pinToggle.dataset.togglePinUrl;
      if (pinToggleUrl) {
        pinToggle.addEventListener("click", async () => {
          try {
            const response = await requests.post(pinToggleUrl);

            if (!response.ok) {
              throw new Response(response);
            }

            const responseData = await response.json();

            window.location = responseData.nextUrl;
          } catch (error) {
            window.Rollbar.warn("Error toggling pinned status of memory", error);
          }
        });
      }
    }

    if (commentBtn && commentField) {
      commentBtn.addEventListener("click", () => {
        commentField.focus();
        trackCustomUserAction(
          window.location.href,
          "memory",
          "click-memory-comment-icon",
        );
      });

      commentField.addEventListener("focus", () => {
        trackCustomUserAction(
          window.location.href,
          "memory",
          "click-memory-comment-field",
        );
      });
    }
  };

  document.querySelectorAll(".memory").forEach(initMemoryInteractivity);
  document.body.addEventListener("htmx:afterSettle", (event) => {
    const newMemory = event.detail.elt;
    if (newMemory && newMemory.classList.contains("memory")) {
      initMemoryInteractivity(newMemory);
      initEditableUserPost(newMemory, props);

      const userPostForm = newMemory.querySelector(".user-post-form");
      if (userPostForm) {
        initUserPostForm(userPostForm);
      }

      newMemory.querySelectorAll(".toggle-dropdown-edit-menu").forEach((menuIcon) => {
        initEditDropdown(menuIcon);
      });
    }
  });

  // Scroll to
  const errorList = [...document.querySelectorAll(".errorlist")].find(isVisible);
  if (!errorList) {
    const searchParams = new URLSearchParams(window.location.search);
    const flow = searchParams.get("flow");
    const uuidToScrollTo = searchParams.get("scroll-to");
    if (uuidToScrollTo) {
      const memoryToScrollTo = document.querySelector(`#${CSS.escape(uuidToScrollTo)}`);
      if (memoryToScrollTo) {
        let offset = -104;
        const managerNavBar = document.querySelector(".secondary-nav-public");
        if (managerNavBar) {
          offset -= managerNavBar.offsetHeight;
        }
        scrollToElement(memoryToScrollTo, offset);
      }
    } else if (
      props.editing ||
      (props.presetMemoryTypeProvided &&
        flow !== "201" &&
        !document.querySelectorAll(".messages-container .alert").length)
    ) {
      scrollToElement(document.querySelector(".memorial-detail-section"), 20);
    }
  }

  if (!props.memoryPostingNotAllowed) {
    initGoogleAutocomplete({
      element: document.getElementById("id_memory-location"),
      metadataElement: document.getElementById("id_memory-location_metadata"),
      options: {
        types: ["establishment", "geocode"],
      },
    });
  }

  if (ingestionModalElement) {
    // Photo ingestion disambiguation
    const socialPhotoImporterContainer = document.querySelector(
      ".social-photo-importer",
    );
    const socialPhotoImporter = new SocialPhotoImporter(
      socialPhotoImporterContainer,
      (urls) => {
        showMultiPhotoUploader();
        multiPhotoUploader.ingestPhotos(urls);
      },
    );

    const uploadButton = ingestionModalElement.querySelector(
      ".ingestion-option.upload",
    );
    if (uploadButton) {
      uploadButton.addEventListener("click", () => {
        ingestionModal.hide();
        showPhotoMemoryForm();
        if (multiPhotoUploader) {
          multiPhotoUploader.show();
          showMultiPhotoUploader();
        } else if (photoFileInput.length) {
          photoFileInput.click();
        }
      });
    }

    const facebookButton = ingestionModalElement.querySelector(
      ".ingestion-option.facebook",
    );
    if (facebookButton) {
      facebookButton.addEventListener("click", () => {
        ingestionModal.hide();
        socialPhotoImporter.show(SocialSource.FACEBOOK);
      });
    }

    const instagramButton = ingestionModalElement.querySelector(
      ".ingestion-option.instagram",
    );
    if (instagramButton) {
      const { instagramOauthUrl } = instagramButton.dataset;
      instagramButton.addEventListener("click", () => {
        ingestionModal.hide();
        socialPhotoImporter.show(SocialSource.INSTAGRAM, instagramOauthUrl);
      });
    }
  }

  window.initReCAPTCHAs = initReCAPTCHAs;

  initPasswordlessSignup();
  initDirtyTracker();
  initPhotoArchiveRequest();
}
