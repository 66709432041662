import "../../../sass/memorials/public/public.scss";

import Carousel from "bootstrap/js/dist/carousel";
import Modal from "bootstrap/js/dist/modal";

import config from "../../core/config";
import initPhotoHeaderSlides from "../../components/PhotoHeaderSlides";
import { initEditDropdowns } from "../../components/EditMenuDropdowns";
import initExpandableCollapses from "../../components/ExpandableCollapse";
import initExpander from "../../components/Expander";
import ExitPopup from "../../components/ExitPopup";
import MasonFlexContainer from "../../components/MasonFlexContainer";
import initMobileShareButton from "../../components/MobileShareButton";
import initPublicAutoScroll from "../../components/PublicAutoScroll";
import { initUserMentions } from "../../components/UserMentions";
import { NavigationTabBarMultiPage } from "../../components/NavigationTabs";
import initSuccessModal from "../../components/SuccessModal";
import requests, { ResponseError } from "../../core/requests";
import {
  animateCSS,
  appendSimpleChildElement,
  handleAjaxFormSubmitProtection,
  initClipboard,
  initDirtyTracker,
  initPopovers,
  initTooltips,
  isVisible,
  scrollToElement,
  sendBrowserAgnosticEvent,
  trackCustomUserAction,
} from "../../core/utils";

import { initAddFuneralHomeWarning } from "../utils";

export { default as initAboutPage } from "./about";
export { default as initMemorialBelongings } from "../../commerce/belongings";
export { default as initMemorialCreate } from "./create";
export { default as initMemorialDonationPage } from "./donate";
export { default as initMemorialEvents } from "./events";
export { default as initFavorites } from "./favorites";
export { default as initFundraising } from "./fundraising";
export { default as initMemorialHelpOut } from "./helpOut";
export { default as initMemories } from "./memories";
export { default as initPublicContentEditing } from "./publicContentEditing";
export { default as initReportMemorial } from "./report";
export { default as initTimeline } from "./timeline";

/**
 * Initialize the click handler for any elements with the `mini-content-object` class.
 */
function initMiniContentObjectClickHandlers() {
  const contentObjects = document.querySelectorAll(".mini-content-object");
  contentObjects.forEach((contentObject) => {
    contentObject.addEventListener("click", () => {
      const redirectUrl = contentObject.dataset.objectUrl;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    });
  });
}

/**
 * Run the code for a public memorial page.
 *
 * @param {object} props - the page props
 */
export function initMemorialPublicPages(props) {
  let exampleMemorialModal;
  if (props.memorialIsExample) {
    exampleMemorialModal = new Modal("#modal-example");
    document.querySelectorAll("form").forEach((form) => {
      form.addEventListener("submit", (e) => {
        e.stopImmediatePropagation();
        e.preventDefault();
        sendBrowserAgnosticEvent(form, "cancel-submit-protect");
        exampleMemorialModal.show();
      });
    });

    document.querySelectorAll(".show-example-modal").forEach((e) => {
      e.addEventListener("click", (ev) => {
        ev.stopImmediatePropagation();
        ev.preventDefault();
        exampleMemorialModal.show();
      });
    });

    document.querySelectorAll("a").forEach((a) => {
      a.addEventListener("click", (ev) => {
        if (a.href.includes("example-user")) {
          ev.stopImmediatePropagation();
          ev.preventDefault();
          exampleMemorialModal.show();
        }
      });
    });
  }

  // Initialize UserMention code
  if (props.userMention) {
    initUserMentions(props);
  }

  initPhotoHeaderSlides();

  const republishSubmit = document.getElementById("memorial-republish-submit");
  if (republishSubmit) {
    republishSubmit.addEventListener("click", () => {
      document.getElementById("memorial-republish-form").submit();
    });
  }

  initExpandableCollapses();

  initMiniContentObjectClickHandlers();

  const subscriptionSuccessBlock = document.querySelector(".subscription-success");
  if (subscriptionSuccessBlock) {
    scrollToElement(subscriptionSuccessBlock, -20);
  }

  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get("subscription-list-success")) {
    const publicSubscriptionListUser = document.querySelector(
      ".user-public-subscriber",
    );
    if (publicSubscriptionListUser) {
      scrollToElement(publicSubscriptionListUser, -20);
    }
  }

  const navContainer = document.querySelector(
    ".nav-tab-container .navigation-container",
  );
  if (navContainer) {
    new NavigationTabBarMultiPage(navContainer);
  }

  // NOTE: this is the callback function for when the FaceBook SDK loads
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: config.facebookAppId,
      autoLogAppEvents: true,
      xfbml: true,
      version: config.facebookApiVersion,
    });

    document.querySelectorAll(".btn-facebook-share").forEach((btnFacebookShare) => {
      btnFacebookShare.addEventListener("click", (event) => {
        if (props.memorialIsExample) {
          exampleMemorialModal.show();
          return;
        }

        const sharingUrl =
          event.currentTarget.getAttribute("data-facebook-share-url") ||
          props.sharingUrl;

        if (config.debug) {
          console.log(`Would share the following link to Facebook: ${sharingUrl}`);
        }

        window.FB.ui(
          {
            method: "share",
            href: sharingUrl,
          },
          (response) => {
            if (response && !response.error_code && typeof response !== "undefined") {
              sendBrowserAgnosticEvent(window, "public-facebook-share-complete");
            }
          },
        );
      });
    });
  };

  // Initialize share buttons if supported.
  initMobileShareButton(".share-cta-container .btn-native-share");
  initMobileShareButton(".sharing-cta-sticky-container .btn-native-share");
  initMobileShareButton("#social-native-share-post-photo-header");
  initMobileShareButton("#social-native-share-pre-photo-header");
  initMobileShareButton("#mobile-slide-native-share");
  initMobileShareButton(".share-obituary-section .btn-native-share");

  initClipboard();
  initEditDropdowns();

  // memorial social module
  document
    .querySelectorAll(".memorial-social-module")
    .forEach((memorialSocialModule) => {
      const heartToggle = memorialSocialModule.querySelector(".heart-toggle");
      if (!heartToggle) {
        return;
      }

      const heartCount = heartToggle.querySelector("span");
      const fullHeart = heartToggle.querySelector("img:first-of-type");

      if (window.location.search.includes("heart-added")) {
        animateCSS(fullHeart, "pulse");
      }

      heartToggle.addEventListener("click", async () => {
        let responseData;

        try {
          const response = await requests.post(heartToggle.dataset.toggleUrl);
          if (!response.ok) {
            throw new ResponseError(response);
          }
          responseData = await response.json();
        } catch (error) {
          window.Rollbar.error("Error hearting a memorial", error);
          return;
        }

        heartToggle
          .querySelectorAll("img")
          .forEach((heartImg) => heartImg.classList.toggle("d-none"));
        if (responseData.heart_count) {
          heartCount.innerText = `Heart (${responseData.heart_count})`;
        } else {
          heartCount.innerText = "Heart";
        }
        if (responseData.is_active) {
          animateCSS(fullHeart, "pulse");
          trackCustomUserAction(
            window.location.href,
            "memorial ctas",
            "click-memorial-heart-on",
          );
        } else {
          trackCustomUserAction(
            window.location.href,
            "memorial ctas",
            "click-memorial-heart-off",
          );
        }
      });
    });

  initSuccessModal();

  const blockUserModalElement = document.getElementById("modal-block-user");
  if (blockUserModalElement) {
    const blockUserModal = new Modal(blockUserModalElement);
    window.showBlockUserModal = (userSlug, userName) => {
      blockUserModalElement.dataset.userToBlock = userSlug;
      blockUserModalElement
        .querySelectorAll(".user-to-block-name")
        .forEach((userBlockName) => {
          userBlockName.innerText = userName;
        });
      blockUserModal.show();
    };

    blockUserModalElement.querySelectorAll(".block-user-button").forEach((blockBtn) => {
      blockBtn.addEventListener("click", async () => {
        const revert = handleAjaxFormSubmitProtection(blockBtn);

        try {
          const response = await requests.post(props.blockUrl, {
            block: true,
            user: blockUserModalElement.dataset.userToBlock,
          });

          if (!response.ok) {
            throw new ResponseError(response);
          }
        } catch (error) {
          blockBtn.innerText = "Error";
          revert();
          window.Rollbar.error("Error blocking a user", error);
          return;
        }

        blockUserModal.hide();
        revert();
      });
    });
  }

  const promptFormsContainer = document.querySelector(".prompt-forms-container");
  if (promptFormsContainer) {
    if (props.scrollToPrompt) {
      scrollToElement(promptFormsContainer, -100);
    }

    Carousel.getOrCreateInstance("#promptFormsCarousel", {
      keyboard: false,
      touch: false,
    });

    promptFormsContainer.querySelectorAll("form").forEach((form) => {
      initDirtyTracker({ requireVisible: true }, form);
    });
  }

  // Init autoscroll
  initPublicAutoScroll(props);

  // Moderation tools
  const moderationTools = document.querySelector(".body-card-moderation-queue");
  if (moderationTools) {
    moderationTools.querySelectorAll('td[data-event="3"]').forEach((td) => {
      initExpander(td);
    });

    const moderationForm = moderationTools.querySelector(".moderation-form");
    const errorList = moderationForm.querySelector(".errorlist");

    const deactivateMemorialInput = moderationForm.querySelector(
      "#id_moderation-deactivate_memorial",
    );
    const deactivateMemorialReason = moderationForm.querySelector(
      "#id_moderation-deactivate_memorial_reason",
    );
    const deactivateMemorialReasonText = moderationForm.querySelector(
      "#id_moderation-deactivate_memorial_reason_text",
    );
    deactivateMemorialInput.addEventListener("change", () => {
      deactivateMemorialReason.disabled = !deactivateMemorialInput.checked;
      deactivateMemorialReason.required = deactivateMemorialInput.checked;
      deactivateMemorialReasonText.disabled = !deactivateMemorialInput.checked;
    });

    const unpublishMemorialInput = moderationForm.querySelector(
      "#id_moderation-unpublish_memorial",
    );
    const unpublishMemorialReason = moderationForm.querySelector(
      "#id_moderation-unpublish_memorial_reason",
    );
    const unpublishMemorialReasonText = moderationForm.querySelector(
      "#id_moderation-unpublish_memorial_reason_text",
    );
    unpublishMemorialInput.addEventListener("change", () => {
      unpublishMemorialReason.disabled = !unpublishMemorialInput.checked;
      unpublishMemorialReason.required = unpublishMemorialInput.checked;
      unpublishMemorialReasonText.disabled = !unpublishMemorialInput.checked;
    });

    moderationForm.addEventListener("submit", async (event) => {
      event.preventDefault();

      const isDeferral = event.submitter.name === "_defer_moderation";
      if (isDeferral) {
        const hiddenNode = document.createElement("input");
        hiddenNode.setAttribute("type", "hidden");
        hiddenNode.setAttribute("name", "_defer_moderation");
        hiddenNode.setAttribute("value", "1");
        moderationForm.appendChild(hiddenNode);
      }

      errorList.replaceChildren();
      errorList.classList.add("d-none");

      let responseData;
      try {
        const response = await requests.post(props.moderateMemorialUrl, moderationForm);
        responseData = await response.json();

        if (!response.ok) {
          if (response.status === 400) {
            sendBrowserAgnosticEvent(moderationForm, "cancel-submit-protect");
            errorList.classList.remove("d-none");

            Object.keys(responseData.errors).forEach((key) => {
              responseData.errors[key].forEach((errorValue) => {
                appendSimpleChildElement(errorList, "li", {}, errorValue.message);
              });
            });
          } else {
            throw new ResponseError(response);
          }

          return;
        }
      } catch {
        sendBrowserAgnosticEvent(moderationForm, "cancel-submit-protect");
        return;
      }

      window.location.replace(responseData.next);
    });
  }

  // Affiliation masonry flow
  document.querySelectorAll(".mason-flex-container").forEach((container) => {
    const mason = new MasonFlexContainer(container);
    mason.flow();
  });

  // Subscribe capture popup.
  if (!props.memorialIsExample) {
    const subscribeCaptureRoot = document.getElementById("subscribe-capture");
    if (subscribeCaptureRoot) {
      window.subscribeCapture = new ExitPopup(subscribeCaptureRoot);
    }
  }

  initPopovers();
  initTooltips();
  initAddFuneralHomeWarning();

  config.whenReadyFunctions.push(() => {
    const errorList = [...document.querySelectorAll(".errorlist")].find((element) =>
      isVisible(element),
    );
    const flow = searchParams.get("flow");

    if (flow === "201" && !errorList) {
      const photoCollageContainer = document.querySelector(
        ".deceased-photo-collage-container",
      );
      if (window.getComputedStyle(photoCollageContainer).display !== "none") {
        let offset = 0;
        const managerNavBar = document.querySelector(".secondary-nav-public");
        if (managerNavBar) {
          offset = -managerNavBar.offsetHeight;
        }

        const mainPhoto = photoCollageContainer.querySelector(".collage-main-photo");
        offset += mainPhoto.offsetHeight / 2;

        scrollToElement(mainPhoto, offset);
      }
    }
  });
}
