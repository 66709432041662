import initBusinessAutocomplete from "./BusinessAutocomplete";
import { scrollToElement, trackCustomUserAction } from "../core/utils";
import initExpandableCollapses, { initExpandableCollapse } from "./ExpandableCollapse";

/**
 * Initialize donor choice charity search and select
 *
 * @param {object} props - the page props
 */
export default function initDonorChoiceCharities(props) {
  const charitySearchInput = initBusinessAutocomplete(props.charitySearch);

  const donateToSearchedCharityButton = document.querySelector(
    ".donate-to-searched-charity",
  );
  if (charitySearchInput) {
    charitySearchInput.tomselect.on("change", () => {
      const charityValue = charitySearchInput.tomselect.getValue();
      if (charityValue) {
        donateToSearchedCharityButton.setAttribute(
          "href",
          `${props.checkoutPathStub}${charityValue}`,
        );
        donateToSearchedCharityButton.classList.remove("d-none");
      } else {
        donateToSearchedCharityButton.classList.add("d-none");
      }
    });
  }

  const charityContainers = document.querySelectorAll(
    ".donor-choice-charity-container",
  );
  const charityOverviewContainers = document.querySelectorAll(
    ".donor-choice-charity-overview",
  );

  charityContainers.forEach((charityContainer) => {
    charityContainer.addEventListener("click", (event) => {
      const selectedCharity = event.target;
      const { charitySlug } = selectedCharity.dataset;

      const matchingOverview = document.querySelector(
        `.donor-choice-charity-overview[data-charity-slug="${charitySlug}"]`,
      );

      charityOverviewContainers.forEach((container) => {
        container.classList.add("d-none");
      });
      if (selectedCharity && selectedCharity.classList.contains("selected")) {
        charityContainers.forEach((container) => {
          container.classList.remove("selected");
        });
      } else {
        charityContainers.forEach((container) => {
          container.classList.remove("selected");
        });

        selectedCharity.classList.add("selected");
        matchingOverview.classList.remove("d-none");

        initExpandableCollapse(matchingOverview.querySelector(".overview-expandable"));
        scrollToElement(selectedCharity, -100);
      }
    });
  });

  const mobileCharitySelect = document.querySelector(
    ".donor-choice-charities-select select",
  );

  if (mobileCharitySelect) {
    mobileCharitySelect.addEventListener("change", () => {
      charityContainers.forEach((container) => {
        container.classList.remove("selected");
      });
      charityOverviewContainers.forEach((container) => {
        container.classList.add("d-none");
      });

      const charitySlug = mobileCharitySelect.value;
      if (!charitySlug) {
        return;
      }

      const charityTagSlug = mobileCharitySelect.selectedOptions[0].dataset.tagSlug;
      const matchingOverview = document.querySelector(
        `.donor-choice-charity-overview[data-charity-slug="${charitySlug}"]`,
      );

      matchingOverview.classList.remove("d-none");
      initExpandableCollapse(matchingOverview.querySelector(".overview-expandable"));
      trackCustomUserAction(
        window.location.href,
        "donate page multiple causes",
        `${charityTagSlug}-learn-more`,
      );
      scrollToElement(matchingOverview, -120);
    });
  }

  initExpandableCollapses();
}
