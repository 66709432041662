import ScrollSpy from "bootstrap/js/dist/scrollspy";
import TomSelect from "tom-select/dist/js/tom-select.base";
import initGoogleAutocomplete from "../../components/GoogleAutocomplete";

/**
 * Initialize the timeline public and private(+onboarding) page behaviors.
 *
 * @param {object} props - the page props
 */
export default function initTimeline(props) {
  let elementToSpy;
  if (props.isPrivate && !props.isOnboarding) {
    elementToSpy = document.querySelector(".private-content-container");
  } else {
    elementToSpy = document.body;
  }

  ScrollSpy.getOrCreateInstance(elementToSpy, {
    rootMargin: "-10% 0px -70%",
    smoothScroll: true,
    target: "#timeline-nav",
    threshold: [0],
  });

  if (props.formFieldIds && Object.keys(props.formFieldIds).length > 0) {
    /**
     * Based on the milestone type and what form values are present, show or hide the
     * relevant dependant form fields of the milestone form.
     *
     * @param {string} milestoneType - the milestone type.
     */
    const updateVisibleFields = (milestoneType) => {
      const detailContainer = document.querySelector(".milestone-form-details");
      if (milestoneType) {
        detailContainer.classList.remove("d-none");

        const otherFieldContainer = document.querySelector(".milestone-other");
        if (milestoneType === "OTHER") {
          otherFieldContainer.classList.remove("d-none");
        } else {
          otherFieldContainer.classList.add("d-none");
          otherFieldContainer.value = "";
        }

        const extraDataContainer = document.querySelector(".milestone-extra-data");
        const extraDataLabel = extraDataContainer.querySelector(".dark-gray-label");
        const extraDataField = document.getElementById(
          props.formFieldIds.milestoneExtraData,
        );

        const extraData = props.milestoneTypesRequiringExtraData[milestoneType];

        if (extraData) {
          const [extraDataTitle, , extraDataExample] = extraData;
          extraDataContainer.classList.remove("d-none");
          extraDataLabel.innerText = extraDataTitle;
          extraDataField.setAttribute("placeholder", extraDataExample);
        } else {
          extraDataContainer.classList.add("d-none");
          extraDataField.value = "";
        }

        const personUsed = props.milestoneTypesUsingPersons.includes(milestoneType);
        const personContainer = document.querySelector(".milestone-person");
        if (personUsed) {
          personContainer.classList.remove("d-none");
          personContainer.querySelector(".person-label").innerText =
            props.milestonePersonLabels[milestoneType];
        } else {
          personContainer.classList.add("d-none");
        }

        document.querySelector(".milestone-location > .location-label").innerText =
          props.milestoneLocationLabels[milestoneType] || "Location";
      } else {
        detailContainer.classList.add("d-none");
      }
    };

    const dateOccurredYearField = document.getElementById(
      props.formFieldIds.milestoneDateOccurred.replace("month", "year"),
    );
    const yearOccurredField = document.getElementById(
      props.formFieldIds.milestoneYearOccurred,
    );

    dateOccurredYearField.required = true;

    if (yearOccurredField.value) {
      dateOccurredYearField.value = yearOccurredField.value;
    }

    dateOccurredYearField.addEventListener("change", () => {
      yearOccurredField.value = dateOccurredYearField.selectedOptions[0].value;
      if (yearOccurredField.value) {
        dateOccurredYearField.required = false;
      }
    });

    const milestoneForm = document.querySelector(".add-milestone-form");
    if (milestoneForm) {
      milestoneForm.addEventListener("submit", () => {
        const dayField = document.getElementById(
          props.formFieldIds.milestoneDateOccurred.replace("month", "day"),
        );
        const monthField = document.getElementById(
          props.formFieldIds.milestoneDateOccurred,
        );

        if (!(dayField.value && monthField.value)) {
          if (dateOccurredYearField.selectedOptions[0].value) {
            yearOccurredField.value = dateOccurredYearField.selectedOptions[0].value;
          }

          dateOccurredYearField.setAttribute("disabled", "disabled");
          dayField.setAttribute("disabled", "disabled");
          monthField.setAttribute("disabled", "disabled");
        }
      });
    }

    const milestoneTypeField = document.getElementById(
      props.formFieldIds.milestoneType,
    );

    if (milestoneTypeField) {
      // Handle initial
      updateVisibleFields(milestoneTypeField.selectedOptions[0].value);

      // Handle changes
      milestoneTypeField.addEventListener("change", () => {
        const selectedMilestoneType = milestoneTypeField.selectedOptions[0].value;
        updateVisibleFields(selectedMilestoneType);
      });
    }

    const personField = document.getElementById(props.formFieldIds.person);
    const clearPersonSelection = document.querySelector(".clear-person-selection");

    const tomSelectConfig = {
      sortField: [{ field: "$order" }, { field: "$score" }],
      create: (input) => {
        const term = input.trim();

        if (term === "") {
          return null;
        }

        return {
          value: "NEW",
          text: `${term} (add new relation)`,
          created: true,
        };
      },
      maxItems: 1,
      placeholder: "Select a related person",
    };

    const personAutocomplete = new TomSelect(personField, tomSelectConfig);

    clearPersonSelection.addEventListener("click", () => {
      personAutocomplete.clear();
      clearPersonSelection.classList.add("d-none");
    });

    personAutocomplete.on("change", (newValue) => {
      if (!newValue) {
        return;
      }

      const selectedOption = personAutocomplete.options[newValue];

      if (!selectedOption) {
        return;
      }

      if (selectedOption.value === "NEW") {
        const personSelect = document.querySelector(".milestone-person-select");
        personSelect.classList.add("d-none");

        const rawNameComponents = selectedOption.text
          .replace(" (add new relation)", "")
          .split(" ");
        let [first, last] = ["", ""];

        if (rawNameComponents.length === 2) {
          // If "A B" then first="A", last="B"
          [first, last] = rawNameComponents;
        } else if (rawNameComponents.length === 1) {
          // if "A" then first="A", last=""
          [first] = rawNameComponents;
        } else {
          // If "A B C D" then first="A", last="B C D"
          [first] = rawNameComponents;
          last = rawNameComponents.slice(1).join(" ");
        }

        const personCreate = document.querySelector(".milestone-person-create");
        personCreate.classList.remove("d-none");
        personCreate
          .querySelector(".survivor-form")
          .querySelectorAll("input,select")
          .forEach((field) => {
            field.removeAttribute("disabled");
          });

        document.getElementById(props.formFieldIds.creatingPerson).value = 1;

        const survivorFirstName = document.getElementById(
          props.formFieldIds.survivorFirstName,
        );
        const survivorLastName = document.getElementById(
          props.formFieldIds.survivorLastName,
        );

        survivorFirstName.value = first;
        survivorLastName.value = last;

        const predeceasedFirstName = document.getElementById(
          props.formFieldIds.predeceasedFirstName,
        );
        const predeceasedLastName = document.getElementById(
          props.formFieldIds.predeceasedLastName,
        );

        predeceasedFirstName.value = first;
        predeceasedLastName.value = last;

        personAutocomplete.clear();
      }
    });

    const survivorRelationshipTypeSelect = document.getElementById(
      props.formFieldIds.survivorRelationshipTypeFieldId,
    );
    const survivorRelationshipTypeStr = document.querySelector(
      ".create-survivor-relationship-other",
    );

    if (survivorRelationshipTypeSelect && survivorRelationshipTypeStr) {
      survivorRelationshipTypeSelect.addEventListener("change", (evt) => {
        const index = evt.target.selectedIndex;
        const selected = evt.target.options[index];
        if (selected.value === "other") {
          survivorRelationshipTypeStr.classList.remove("d-none");
        } else {
          survivorRelationshipTypeStr.querySelector("input").value = "";
          survivorRelationshipTypeStr.classList.add("d-none");
        }
      });
    }

    const predeceasedRelationshipTypeSelect = document.getElementById(
      props.formFieldIds.predeceasedRelationshipTypeFieldId,
    );
    const predeceasedRelationshipTypeStr = document.querySelector(
      ".create-predeceased-relationship-other",
    );

    if (predeceasedRelationshipTypeSelect && predeceasedRelationshipTypeStr) {
      predeceasedRelationshipTypeSelect.addEventListener("change", (evt) => {
        const index = evt.target.selectedIndex;
        const selected = evt.target.options[index];
        if (selected.value === "other") {
          predeceasedRelationshipTypeStr.classList.remove("d-none");
        } else {
          predeceasedRelationshipTypeStr.querySelector("input").value = "";
          survivorRelationshipTypeStr.classList.add("d-none");
        }
      });
    }

    document.querySelectorAll(".create-person-tab").forEach((createPersonTab) => {
      createPersonTab.addEventListener("hidden.bs.tab", (event) => {
        const tabContent = document.querySelector(event.target.dataset.bsTarget);
        tabContent.querySelectorAll("input").forEach((input) => {
          input.value = "";
        });
        tabContent.querySelectorAll("input,select").forEach((field) => {
          field.setAttribute("disabled", "disabled");
        });
      });

      createPersonTab.addEventListener("shown.bs.tab", (event) => {
        const tabContent = document.querySelector(event.target.dataset.bsTarget);
        tabContent.querySelectorAll("input,select").forEach((field) => {
          field.removeAttribute("disabled");
        });
      });
    });

    const locationField = document.getElementById(props.formFieldIds.milestoneLocation);
    const metadataField = document.getElementById(
      props.formFieldIds.milestoneLocationMetadata,
    );
    if (locationField && metadataField) {
      initGoogleAutocomplete({
        element: locationField,
        metadataElement: metadataField,
        options: {
          types: ["establishment", "geocode"],
        },
      });
    }

    const predeceasedLocationContainer = document.querySelector(
      ".predeceased-location-container",
    );
    if (predeceasedLocationContainer) {
      initGoogleAutocomplete({
        element: predeceasedLocationContainer.querySelector("input"),
        options: {
          types: ["(cities)"],
        },
      });
    }
  }

  if (props.blockUserPromptData && window.showBlockUserModal) {
    const location = window.location.toString();
    if (location.indexOf("?") > 0) {
      window.history.replaceState(
        {},
        document.title,
        location.substring(0, location.indexOf("?")),
      );
    }
    window.showBlockUserModal(
      props.blockUserPromptData.userSlug,
      props.blockUserPromptData.userName,
    );
  }
}
