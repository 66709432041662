import config from "../core/config";

export default function initFacebookLogin(props) {
  const fbLoginBtn = document.querySelector(".fb-login-btn");
  const fbLoginErrorlist = document.querySelector(".fb-login-errorlist");

  const addErrorMessage = (message) => {
    const child = document.createElement("li");
    child.textContent = message;
    fbLoginErrorlist.appendChild(child);
    fbLoginErrorlist.classList.remove("d-none");
  };

  const clearErrorMessages = () => {
    fbLoginErrorlist.classList.add("d-none");
    while (fbLoginErrorlist.hasChildNodes()) {
      fbLoginErrorlist.removeChild(fbLoginErrorlist.lastChild);
    }
  };

  const handleSuccessfulLogin = (response) => {
    window.FB.api("/me/permissions", (permissionsResponse) => {
      const declined = [];
      for (let i = 0; i < permissionsResponse.data.length; i += 1) {
        if (permissionsResponse.data[i].status === "declined") {
          declined.push(permissionsResponse.data[i].permission);
        }
      }

      if (declined.length === 0) {
        const facebookLoginForm = document.getElementById("facebook-login-form");
        const facebookAccessTokenInput = document.getElementById(
          props.facebookAccessTokenInputId,
        );
        const facebookAccountIdInput = document.getElementById(
          props.facebookAccountIdInputId,
        );

        facebookAccessTokenInput.value = response.authResponse.accessToken;
        facebookAccountIdInput.value = response.authResponse.userID;
        facebookLoginForm.submit();
      } else {
        addErrorMessage(props.missingEmailMessage);
      }
    });
  };

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: config.facebookAppId,
      cookie: true,
      xfbml: true,
      version: config.facebookApiVersion,
    });

    fbLoginBtn.addEventListener("click", () => {
      clearErrorMessages();
      window.FB.login(
        (response) => {
          if (response.status === "connected") {
            handleSuccessfulLogin(response);
          } else {
            addErrorMessage(
              "An error occurred and we were unable to log you in with Facebook. Please create an account using your email and password.",
            );
          }
        },
        { scope: "public_profile,email" },
      );
    });
  };
}
