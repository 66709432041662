import Modal from "bootstrap/js/dist/modal";

import initGoogleAutocomplete from "../../components/GoogleAutocomplete";
import initCauseOfDeathAutocomplete from "../../components/CauseOfDeathAutocomplete";
import initBusinessAutocomplete from "../../components/BusinessAutocomplete";
import initReCAPTCHAs from "../../core/reCAPTCHA";

function updateRequiredAttributes(editModal, formContainer) {
  const contentFields = editModal.querySelectorAll(
    ".editable-content-form input,select",
  );
  contentFields.forEach((field) => {
    if (!formContainer.contains(field)) {
      field.required = false;
    }
  });
}

export default function initPublicContentEditing(props) {
  const editModalElement = document.querySelector(".edit-content-modal");

  if (!editModalElement) {
    return;
  }

  const editModal = new Modal(editModalElement);

  if (props.showContentEditModal) {
    editModal.show();
    const formContainer = editModalElement.querySelector(
      ".editable-content-form:not(.d-none)",
    );
    updateRequiredAttributes(editModalElement, formContainer);
  }

  const locationFieldContainer = editModalElement.querySelector(".location-field");
  if (locationFieldContainer) {
    initGoogleAutocomplete({
      element: locationFieldContainer.querySelector('input[type="text"]'),
      options: {
        types: ["(cities)"],
      },
    });
  }

  const causeOfDeathFormContainer = editModalElement.querySelector(
    '.editable-content-form[data-content-type="cause_of_death"]',
  );
  if (causeOfDeathFormContainer) {
    initCauseOfDeathAutocomplete({
      ...props.causeOfDeathSearch,
    });
  }

  const finalRestingPlaceFormContainer = editModalElement.querySelector(
    '.editable-content-form[data-content-type="final_resting_place"]',
  );
  if (finalRestingPlaceFormContainer) {
    const remainsLocationTypeSelect = finalRestingPlaceFormContainer.querySelector(
      ".remains-location-type-container select",
    );
    const cemeterySearchContainer =
      finalRestingPlaceFormContainer.querySelector(".cemetery-search");
    const locationEntryContainer =
      finalRestingPlaceFormContainer.querySelector(".remains-location");
    if (remainsLocationTypeSelect) {
      remainsLocationTypeSelect.addEventListener("change", () => {
        const selected = remainsLocationTypeSelect.selectedOptions[0];
        if (selected && selected.value) {
          // This is the value for CEMETERY -- hard to consistently pass into props
          // from every public page view, so we use a magic number here.
          // TODO: update this when common public site props are an available feature.
          if (selected.value === "1") {
            cemeterySearchContainer.classList.remove("d-none");
            locationEntryContainer.classList.add("d-none");
          } else {
            cemeterySearchContainer.classList.add("d-none");
            locationEntryContainer.classList.remove("d-none");
          }
        }
      });
    }

    if (cemeterySearchContainer && props.cemeterySearch) {
      initBusinessAutocomplete(props.cemeterySearch);
    }

    if (locationEntryContainer) {
      const locationField = locationEntryContainer.querySelector('input[type="text"]');
      const locationMetadataField =
        locationEntryContainer.querySelector('input[type="hidden"]');

      initGoogleAutocomplete({
        element: document.getElementById(locationField.id),
        metadataElement: document.getElementById(locationMetadataField.id),
        options: {
          types: ["establishment", "geocode"],
        },
      });
    }
  }

  const addContentButtons = document.querySelectorAll(".add-content");
  addContentButtons.forEach((button) => {
    if (button.href !== "javascript:;") {
      return;
    }

    button.addEventListener("click", () => {
      const contentType = button.getAttribute("data-content-type");
      const formContainer = editModalElement.querySelector(
        `.editable-content-form[data-content-type="${contentType}"]`,
      );

      if (!formContainer) {
        return;
      }

      const contentTypeField = editModalElement.querySelector(
        'input[name="_submit_editable_content"]',
      );
      contentTypeField.value = contentType;

      updateRequiredAttributes(editModalElement, formContainer);

      formContainer.classList.remove("d-none");

      if (contentType === "location" || contentType === "final_resting_place") {
        const input = formContainer.querySelector(".pac-target-input");
        if (input) {
          input.setAttribute("placeholder", "");
        }
        const autocompleteBox = document.querySelector(".pac-container");
        if (autocompleteBox) {
          autocompleteBox.style.zIndex = 2500;
        }
      }

      editModal.show();
    });
  });

  editModalElement.addEventListener("hidden.bs.modal", () => {
    if (window.Beacon) {
      window.Beacon("config", { hideFABOnMobile: false });
    }

    editModalElement
      .querySelectorAll(".editable-content-form")
      .forEach((formContainer) => {
        formContainer.classList.add("d-none");
      });
  });

  editModalElement.addEventListener("shown.bs.modal", () => {
    if (window.Beacon) {
      window.Beacon("config", { hideFABOnMobile: true });
    }
  });

  window.initReCAPTCHAs = initReCAPTCHAs;
}
