import initGoogleAutocomplete from "./GoogleAutocomplete";
import initUserFinderAutocomplete from "./Participant";
import { scrollToElement } from "../core/utils";
import { UserMentionWriteHandler } from "./UserMentions";
import requests, { ResponseError } from "../core/requests";
import { postMultipartWithProgress } from "../memorials/utils";

/**
 * Encapsulate the memory photo upload behavior -- supports photos from both user-device
 * and social-network locations.
 */
export default class MemoryMultiPhotoUploader {
  constructor(
    uploadForm,
    formsetPrefix,
    emptyFormsetFormTemplate,
    existingFormsetForms,
    userMentionProps,
    availableDeceasedPhotoSlots,
  ) {
    this.availableDeceasedPhotoSlots = availableDeceasedPhotoSlots || 0;
    this.formsetPrefix = formsetPrefix;
    this.userMentionProps = userMentionProps;
    this.uploadForm = uploadForm;
    this.input = this.uploadForm.querySelector(".multi-photo-upload-input");
    this.groupUUIDInput = this.uploadForm.querySelector('input[name="group_uuid"]');
    this.emptyFormsetFormTemplate = emptyFormsetFormTemplate;
    this.formsetForms = existingFormsetForms ? Array.from(existingFormsetForms) : [];
    this.formsetForms.forEach((form, idx) => {
      this.initializeForm(form, idx);
    });
    this.addPhotosButton = this.uploadForm.querySelector(".add-photo-memory-button");

    this.initialFormCountInput = this.uploadForm.querySelector(
      `#${this.formsetPrefix}-INITIAL_FORMS`,
    );
    this.totalFormsInput = this.uploadForm.querySelector(
      `#${this.formsetPrefix}-TOTAL_FORMS`,
    );

    this.addPhotosButton.addEventListener("click", () => {
      this.input.click();
    });

    this.input.addEventListener("change", () => {
      this.uploadPhotos();
    });

    this.loadingDialog = this.uploadForm.querySelector(".overlay.loading");
    this.errorDialog = this.uploadForm.querySelector(".overlay.error");
    this.progressBar = this.loadingDialog.querySelector(".el-progress > *");
  }

  /**
   * Show the uploader UX.
   */
  show() {
    if (this.formsetForms.length) {
      this.input.classList.add("d-none");
    } else {
      this.input.click();
    }
  }

  /**
   * Initialize some dependencies of the photo memory formset form: Google Autocomplete,
   * Participant autocomplete
   *
   * @param {HTMLFormElement} newForm - the form element
   * @param {number} idx - the index of the formset form
   * @param {boolean } [shouldAutoCheckAddToMemorialHeader] - if true,
   *     automatically `check` the checkbox to add the photo to the memorial header.
   */
  initializeForm(newForm, idx, shouldAutoCheckAddToMemorialHeader = false) {
    const memoryParticipantProps = {
      participantsInputIds: {
        participants: `${this.formsetPrefix}-${idx}-participants`,
        participantsJson: `${this.formsetPrefix}-${idx}-participants_json`,
      },
      userMention: this.userMentionProps,
    };

    initUserFinderAutocomplete(memoryParticipantProps);

    const previousFormFields =
      idx > 0
        ? this.formsetForms[idx - 1].querySelector(".photo-memory-form-fields")
        : null;
    const formFields = newForm.querySelector(".photo-memory-form-fields");

    const yearContainer = formFields.querySelector(".year");
    const yearInput = yearContainer.querySelector('input[type="text"]');
    yearInput.addEventListener("input", () => {
      this.updateCopyButtons();
    });

    const locationContainer = formFields.querySelector(".location");
    const locationInput = locationContainer.querySelector('input[type="text"]');
    const locationMdInput = locationContainer.querySelector('input[type="hidden"]');
    locationInput.addEventListener("input", () => {
      this.updateCopyButtons();
    });

    const captionContainer = formFields.querySelector(".caption");
    const captionInput = captionContainer.querySelector('input[type="text"]');
    captionInput.addEventListener("input", () => {
      this.updateCopyButtons();
    });

    if (previousFormFields) {
      const previousYearContainer = previousFormFields.querySelector(".year");
      const previousYearInput =
        previousYearContainer.querySelector('input[type="text"]');
      yearContainer
        .querySelector(".copy-photo-memory-detail")
        .addEventListener("click", () => {
          yearInput.value = previousYearInput.value;
          this.updateCopyButtons();
        });

      const previousLocationContainer = previousFormFields.querySelector(".location");
      const previousLocationInput =
        previousLocationContainer.querySelector('input[type="text"]');
      const previousLocationMdInput =
        previousLocationContainer.querySelector('input[type="hidden"]');
      locationContainer
        .querySelector(".copy-photo-memory-detail")
        .addEventListener("click", () => {
          locationInput.value = previousLocationInput.value;
          locationMdInput.value = previousLocationMdInput.value;
          this.updateCopyButtons();
        });

      const previousCaptionContainer = previousFormFields.querySelector(".caption");
      const previousCaptionInput =
        previousCaptionContainer.querySelector('input[type="text"]');
      captionContainer
        .querySelector(".copy-photo-memory-detail")
        .addEventListener("click", () => {
          captionInput.value = previousCaptionInput.value;
          this.updateCopyButtons();
        });
    }

    this.updateCopyButtons();

    const removeButton = newForm.querySelector(".remove-photo-memory-button");
    const removeField = newForm.querySelector(
      `input[name="remove-photo-memory-${idx}"]`,
    );
    if (removeButton && removeField) {
      removeButton.addEventListener("click", () => {
        newForm.classList.add("removing");
        removeField.value = "1";

        if (this.formsetForms.length === 1) {
          document.querySelector(".save-memory-photo-formset-btn").click();
        }
      });
    }

    const promoteToHeaderContainer = newForm.querySelector(".promote-to-header");
    if (promoteToHeaderContainer) {
      const checkbox = promoteToHeaderContainer.querySelector('input[type="checkbox"]');
      if (
        shouldAutoCheckAddToMemorialHeader &&
        document.querySelectorAll('.promote-to-header input[type="checkbox"]:checked')
          .length < this.availableDeceasedPhotoSlots
      ) {
        checkbox.checked = true;
      }

      const checkShouldHide = () => {
        const checked = document.querySelectorAll(
          '.promote-to-header input[type="checkbox"]:checked',
        );
        const hideUnchecked = checked.length >= this.availableDeceasedPhotoSlots;
        document.querySelectorAll(".promote-to-header").forEach((con) => {
          const cb = con.querySelector('input[type="checkbox"]');
          con.classList.toggle("d-none", hideUnchecked ? !cb.checked : false);
        });
      };

      checkbox.addEventListener("change", checkShouldHide);
      checkShouldHide();
    }

    initGoogleAutocomplete({
      element: document.getElementById(`${this.formsetPrefix}-${idx}-location`),
      metadataElement: document.getElementById(
        `${this.formsetPrefix}-${idx}-location_metadata`,
      ),
      options: {
        types: ["establishment", "geocode"],
      },
    });
  }

  /**
   * Add a form to the formset
   *
   * @param {string} id - the ID of the new Memory to set as the value of the formset
   *     form's ID field.
   * @param {string} photoUrl - the URL to the photo.
   * @param {boolean} [shouldAutoCheckAddToMemorialHeader] - if true, will pre-
   *     `check` the "add to memorial header" checkbox.
   */
  addForm(id, photoUrl, shouldAutoCheckAddToMemorialHeader = false) {
    const idx = this.formsetForms.length;
    const newFormWrapper = document.createElement("div");

    newFormWrapper.innerHTML = this.emptyFormsetFormTemplate.replace(
      /__prefix__/g,
      idx,
    );

    const newForm = newFormWrapper.querySelector(".photo-memory-form");
    if (newForm) {
      this.totalFormsInput.value = idx + 1;
      this.initialFormCountInput.value = idx + 1;
      this.formsetForms.push(newForm);
      this.uploadForm
        .querySelector(".photo-memory-forms-container")
        .insertBefore(newForm, this.addPhotosButton);

      const idInput = document.getElementById(`${this.formsetPrefix}-${idx}-id`);
      const memoryTypeInput = document.getElementById(
        `${this.formsetPrefix}-${idx}-memory_type`,
      );
      const captionInput = document.getElementById(
        `${this.formsetPrefix}-${idx}-caption`,
      );
      const photoContainer = newForm.querySelector(".photo-memory-form-photo");
      const photoImg = photoContainer.querySelector("img.memory-photo");

      idInput.setAttribute("value", id);
      memoryTypeInput.setAttribute("value", "0");
      photoContainer.style.backgroundImage = `url(${photoUrl})`;
      photoImg.setAttribute("src", photoUrl);
      captionInput.setAttribute("placeholder", "Example: A trip to the park");

      // Set up user mentions on new form.
      const { memorialSlug, userMentionClass, userSearchEndpoint } =
        this.userMentionProps;
      const mentionable = newForm.querySelector(`.${userMentionClass}`);
      const handler = new UserMentionWriteHandler(
        mentionable,
        memorialSlug,
        userSearchEndpoint,
      );
      handler.setUp();

      // Run basic init as well.
      this.initializeForm(newForm, idx, shouldAutoCheckAddToMemorialHeader);
    }
  }

  /**
   * Ingest the photos at the given URLs provided by social networks.
   *
   * @param {Array<string>} urls - a list of URLs.
   * @returns {Promise<void>} - an ignorable void promise
   */
  async ingestPhotos(urls) {
    this.errorDialog.classList.add("d-none");
    this.loadingDialog.classList.remove("d-none");
    this.addPhotosButton.classList.add("d-none");

    const loadFinish = setInterval(() => {
      if (this.loadingDialog.classList.contains("d-none") || !this.progressBar) {
        clearInterval(loadFinish);
        return;
      }

      const currentValue = parseInt(this.progressBar.value, 10);
      if (currentValue < 100) {
        this.progressBar.value = currentValue + 1;
      } else {
        clearInterval(loadFinish);
      }
    }, 20);

    try {
      const formData = new FormData();
      urls.forEach((url) => formData.append("url", url));

      const response = await requests.post(
        this.uploadForm.dataset.uploadAction,
        formData,
      );

      if (!response.ok) {
        throw new ResponseError(response);
      }

      const responseData = await response.json();

      this.receiveNewPhotoMemories(responseData);
    } catch (error) {
      window.Rollbar.warn("Error uploading memory-multi-photos", error);
      this.errorDialog.classList.remove("d-none");
    }

    this.loadingDialog.classList.add("d-none");
    this.input.value = null;
  }

  /**
   * Upload user-selected photos from the upload form to the backend.
   *
   * @returns {Promise<void>} an ignorable promise.
   */
  async uploadPhotos() {
    const formData = new FormData(this.uploadForm);

    this.errorDialog.classList.add("d-none");
    this.loadingDialog.classList.remove("d-none");
    this.addPhotosButton.classList.add("d-none");

    // Capture the progressbar and loading dialog for the listener below.
    const { progressBar, loadingDialog } = this;
    const uploadCount = this.input.files.length;

    const onProgressPercentage = (progressPercentage) => {
      progressBar.value = progressPercentage * 50;

      // The second half of the progress is estimating the processing time
      // on the other end. Interval number here is just kind of a magic
      // number that makes the rest of the bar go at a reasonable speed.
      if (progressPercentage >= 100) {
        const loadFinish = setInterval(() => {
          if (loadingDialog.classList.contains("d-none") || !progressBar) {
            clearInterval(loadFinish);
            return;
          }

          const currentValue = parseInt(progressBar.value, 10);
          if (currentValue < 100) {
            progressBar.value = currentValue + 1;
          } else {
            clearInterval(loadFinish);
          }
        }, uploadCount * 40);
      }
    };

    let response;

    try {
      response = await postMultipartWithProgress(
        this.uploadForm.dataset.uploadAction,
        formData,
        onProgressPercentage,
      );

      this.receiveNewPhotoMemories(response);
    } catch (error) {
      window.Rollbar.error("Error uploading multi-Memory-photos", error);

      this.errorDialog.classList.remove("d-none");
    }

    this.loadingDialog.classList.add("d-none");
    this.addPhotosButton.classList.remove("d-none");
    this.input.value = null;
  }

  /**
   * After upload/submit of new photos, load up formset forms for the newly-created
   * memories
   *
   * @param {object} memoriesData - JSON data about the newly-created memories.
   */
  receiveNewPhotoMemories(memoriesData) {
    const shouldAutoCheck =
      (memoriesData.photoMemories || []).length + this.formsetForms.length <=
      this.availableDeceasedPhotoSlots;
    (memoriesData.photoMemories || []).forEach((newPhotoMemory) => {
      this.addForm(newPhotoMemory.id, newPhotoMemory.url, shouldAutoCheck);
    });

    this.groupUUIDInput.value = memoriesData.groupUUID;

    this.uploadForm.querySelector(".alert").classList.remove("d-none");
    scrollToElement(this.uploadForm);

    const memorySelectors = document.querySelector(".memory-selectors");
    if (memorySelectors) {
      memorySelectors.classList.add("d-none");
    }
  }

  /**
   * Update the "copy from other memory" buttons on formset forms.
   */
  updateCopyButtons() {
    this.formsetForms.forEach((form, idx, arr) => {
      const previous = idx > 0 ? arr[idx - 1] : null;

      // Year
      const yearInputContainer = form.querySelector(
        ".photo-memory-form-fields > .year",
      );
      const yearInput = yearInputContainer.querySelector('input[type="text"]');
      const yearCopyBtn = yearInputContainer.querySelector(".copy-photo-memory-detail");

      yearCopyBtn.classList.toggle("d-none", !previous);

      if (previous) {
        const previousYearInput = previous.querySelector(
          '.photo-memory-form-fields > .year > input[type="text"]',
        );
        yearCopyBtn.classList.toggle(
          "d-none",
          (yearInput.value && !previousYearInput.value.startsWith(yearInput.value)) ||
            previousYearInput.value === yearInput.value,
        );
      }

      // Location
      const locInputContainer = form.querySelector(
        ".photo-memory-form-fields > .location",
      );
      const locInput = locInputContainer.querySelector('input[type="text"]');
      const locCopyBtn = locInputContainer.querySelector(".copy-photo-memory-detail");

      locCopyBtn.classList.toggle("d-none", !previous);

      if (previous) {
        const previousLocInput = previous.querySelector(
          '.photo-memory-form-fields > .location > input[type="text"]',
        );
        locCopyBtn.classList.toggle(
          "d-none",
          (locInput.value && !previousLocInput.value.startsWith(locInput.value)) ||
            previousLocInput.value === locInput.value,
        );
      }

      // Caption
      const captionInputContainer = form.querySelector(
        ".photo-memory-form-fields > .caption",
      );
      const captionInput = captionInputContainer.querySelector('input[type="text"]');
      const captionCopyBtn = captionInputContainer.querySelector(
        ".copy-photo-memory-detail",
      );

      captionCopyBtn.classList.toggle("d-none", !previous);

      if (previous) {
        const previousCaptionInput = previous.querySelector(
          '.photo-memory-form-fields > .caption > input[type="text"]',
        );
        captionCopyBtn.classList.toggle(
          "d-none",
          (captionInput.value &&
            !previousCaptionInput.value.startsWith(captionInput.value)) ||
            previousCaptionInput.value === captionInput.value,
        );
      }
    });
  }
}
