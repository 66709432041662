import initCharacterLimitTextInput from "../components/CharacterLimitTextInput";
import initFacebookLogin from "../components/FacebookLogin";
import initOrganizationSelect from "../components/OrganizationSelect";
import { sendBrowserAgnosticEvent } from "../core/utils";

/**
 * Initialize our Signup component.
 *
 * @param {object} props - the page props
 */
export function initSignup(props) {
  const isBusinessToggle = document.querySelector(".is-business-toggle");
  const businessSelect = document.querySelector(".business-select");
  const businessTypeSelect = document.querySelector(".business-type-select");
  const socialAuth = document.querySelector(".social-auth");
  const welcomeInput = document.querySelector('input[name="welcome"]');

  if (props.shouldUseAutocomplete) {
    initOrganizationSelect(props);
    businessTypeSelect.required = false;

    isBusinessToggle.addEventListener("change", () => {
      if (isBusinessToggle.checked) {
        socialAuth.classList.add("d-none");
        businessSelect.classList.remove("d-none");
        businessTypeSelect.required = true;
      } else {
        socialAuth.classList.remove("d-none");
        businessSelect.classList.add("d-none");
        sendBrowserAgnosticEvent(businessSelect, "clearallinputs");
        businessTypeSelect.required = false;
      }

      if (welcomeInput) {
        welcomeInput.value = props.initialWelcomeValue;
      }
    });

    if (props.businessTypeOpen) {
      isBusinessToggle.checked = true;
      sendBrowserAgnosticEvent(isBusinessToggle, "change");
    }
  }

  initFacebookLogin(props.facebookLoginForm);

  const relationshipSelect = document.querySelector(
    ".relationship-type-select > select",
  );
  const relationshipOther = document.querySelector(".relationship-other");

  if (relationshipSelect && relationshipOther) {
    relationshipSelect.addEventListener("change", (evt) => {
      const index = evt.target.selectedIndex;
      const selected = evt.target.options[index];
      if (selected.value === "other") {
        relationshipOther.classList.remove("d-none");
      } else {
        relationshipOther.querySelector("input").value = "";
        relationshipOther.classList.add("d-none");
      }
    });

    const relationshipOtherInput = relationshipOther.querySelector("input");
    const relationshipOtherOverLengthWarning =
      relationshipOther.parentNode.querySelector(
        ".relationship-other-over-length-warning",
      );

    if (
      relationshipOtherInput &&
      relationshipOtherInput.classList.contains("enforce-max-length")
    ) {
      initCharacterLimitTextInput(
        relationshipOtherInput,
        relationshipOtherInput.maxLength,
        true,
        relationshipOther.querySelector(".character-limit"),
        (remaining) => {
          if (relationshipOtherOverLengthWarning) {
            relationshipOtherOverLengthWarning.classList.toggle(
              "d-none",
              remaining > 0,
            );
          }
          return `${remaining}`;
        },
      );
    }
  }
}

export function initPasswordlessSignup() {
  // Handle passwordless signup form relationship-select
  document
    .querySelectorAll(".passwordless-signup-relationship")
    .forEach((relationshipForm) => {
      const relationshipSelect = relationshipForm.querySelector(
        ".relationship-type-select > select",
      );
      const relationshipOther = relationshipForm.querySelector(".relationship-other");
      if (relationshipSelect && relationshipOther) {
        relationshipSelect.addEventListener("change", (evt) => {
          const index = evt.target.selectedIndex;
          const selected = evt.target.options[index];
          if (selected.value === "other") {
            relationshipOther.classList.remove("d-none");
          } else {
            relationshipOther.querySelector("input").value = "";
            relationshipOther.classList.add("d-none");
          }
        });

        const relationshipOtherInput = relationshipOther.querySelector("input");
        const relationshipOtherOverLengthWarning =
          relationshipOther.parentNode.querySelector(
            ".relationship-other-over-length-warning",
          );

        if (
          relationshipOtherInput &&
          relationshipOtherInput.classList.contains("enforce-max-length")
        ) {
          initCharacterLimitTextInput(
            relationshipOtherInput,
            relationshipOtherInput.maxLength,
            true,
            relationshipOther.querySelector(".character-limit"),
            (remaining) => {
              if (relationshipOtherOverLengthWarning) {
                relationshipOtherOverLengthWarning.classList.toggle(
                  "d-none",
                  remaining > 0,
                );
              }
              return `${remaining}`;
            },
          );
        }
      }
    });
}
