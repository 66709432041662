import { scrollToElement } from "../core/utils";

export default function initPublicAutoScroll(props) {
  if (props.autoScrollTargets) {
    const autoScroll = () => {
      Object.keys(props.autoScrollTargets).forEach((hash) => {
        if (window.location.hash === hash) {
          const node = document.querySelector(props.autoScrollTargets[hash]);
          if (node) {
            scrollToElement(node, -20);
          }
        }
      });
    };

    window.onhashchange = autoScroll;
    autoScroll();
  }
}
