import Modal from "bootstrap/js/dist/modal";

import initReCAPTCHAs from "../../core/reCAPTCHA";

export default function initReportMemorial(props) {
  const btnSubmit = document.querySelector(".btn-form-submit button");
  const radios = document.querySelectorAll(`[name="${props.reasonRadiosName}"]`);
  const hiddenDivs = document.querySelectorAll("[data-show-when-checked]");

  const inaccurateModalElement = document.getElementById("inaccurateModal");
  const familyModalElement = document.getElementById("familyModal");
  if (familyModalElement) {
    const familyRadios = familyModalElement.querySelectorAll('[name="family-reasons"]');
    const familyHiddenDivs = document.querySelectorAll(
      "[data-family-show-when-checked]",
    );

    function hideFamilyHiddenDivs() {
      familyHiddenDivs.forEach((familyHiddenDiv) => {
        familyHiddenDiv.classList.add("d-none");
      });
    }

    familyRadios.forEach((familyRadio) => {
      familyRadio.addEventListener("change", () => {
        hideFamilyHiddenDivs();
        document
          .querySelector(`[data-family-show-when-checked="${familyRadio.value}"]`)
          .classList.toggle("d-none", !familyRadio.checked);
      });
    });

    familyModalElement.addEventListener("hidden.bs.modal", () => {
      familyRadios.forEach((familyRadio) => {
        familyRadio.checked = false;
      });
      hideFamilyHiddenDivs();
    });
  }

  function handleRadioChange(props, radio, hiddenDiv) {
    if (
      !props.isUsingPersonalFundraising &&
      radio.value === props.ReportReason.FRAUDULENT.toString()
    ) {
      btnSubmit.disabled = true;
    }

    if (
      inaccurateModalElement &&
      radio.value === props.ReportReason.INACCURATE.toString()
    ) {
      Modal.getOrCreateInstance(inaccurateModalElement).show();
    }

    if (
      inaccurateModalElement &&
      radio.value === props.ReportReason.FAMILY.toString()
    ) {
      Modal.getOrCreateInstance(familyModalElement).show();
    }

    if (hiddenDiv) {
      hiddenDiv.classList.toggle("d-none", !radio.checked);
    }
  }

  radios.forEach((radio) => {
    radio.addEventListener("change", () => {
      btnSubmit.disabled = false;
      hiddenDivs.forEach((hiddenDiv) => {
        hiddenDiv.classList.add("d-none");
      });

      handleRadioChange(
        props,
        radio,
        document.querySelector(`[data-show-when-checked="${radio.id}"]`),
      );
    });
  });

  const checkedRadio = document.querySelector(
    `[name="${props.reasonRadiosName}"]:checked`,
  );
  if (checkedRadio) {
    handleRadioChange(
      props,
      checkedRadio,
      document.querySelector(`[data-show-when-checked="${checkedRadio.id}"]`),
    );
  }

  window.initReCAPTCHAs = initReCAPTCHAs;
}
